.image-cards {
	margin-bottom: 90px;
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: 40px 30px;
	@media (min-width: 25em) {
		grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
	}
}

.image-card {
	max-width: 413px;
	background: #FFFFFF;
	text-align: center;
	box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.2);
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	img {
		margin-bottom: 0;
	}
	h2 {
		font-family: $heading-font;
		font-size: rem-calc(30);
		font-weight: 400;
		line-height: 1.1;
		margin-bottom: 16px;
	}
	p {
		margin-bottom: 30px;
	}
	.content {
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-start;
		padding: 20px 30px 30px;
	}
	.button {
		margin-top: auto;
		margin-bottom: 0;
	}
}