$nav-bg-color: #FFFFFF;

body.offscreen-nav-visible {
	position: relative;
	&::before {
		content: '';
		width: 100%;
		height: 100%;
		background: #000000;
		opacity: .6;
		z-index: 1500;
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
	}
}

.offscreen-nav {
	width: 100%;
	max-width: 375px;
	height: 100%;
	background-color: $nav-bg-color;
	padding: 20px 30px 0;
	display: flex;
	flex-direction: column;
	position: fixed;
	top: 0;
	right: 0;
	z-index: 500;
	transform: translateX(100%);
	transition: transform .3s ease-in-out, visibility .3s ease-in-out;
	visibility: hidden;
	&.visible {
		z-index: 2100;
		visibility: visible;
		transform: translateX(0);
		transition: transform .3s ease-in-out, visibility .3s ease-in-out;
	}
	.top-level {
		margin-bottom: 30px;
		a {
			color: $primary-brand;
			border-bottom: solid 3px transparent;
			padding-bottom: 0;
			&.current,
			&:hover,
			&:focus {
				border-color: $brand-bright;
			}
		}
		.selected a {
			border-color: $brand-bright;
		}
		li {
			font-size: rem-calc(18);
			font-weight: bold;
			line-height: 1.55;
			padding-bottom: 12px;
			margin-bottom: 0;
			&:last-child {
				padding-bottom: 0;
			}
		}
	}
	.extra-nav {
		background: $primary-accent;
		padding: 30px;
		margin: 0 -30px;
		flex-grow: 1;
		ul {
			margin: 0 0 20px;
		}
		li {
			list-style: none;
			margin-bottom: 12px;
		}
		a {
			color: $white;
			&:hover, &:focus {
				color: $yellow;
			}
		}
		.email-icon a, .phone-icon a {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			gap: 8px;
			img {
				margin: 0;
			}
		}
	}
	.social-media {
		display: flex;
		gap: 15px;
		a {
			color: $white;
			line-height: 1;
			&:hover, &:focus {
				color: $yellow;
			}
		}
		svg {
			width: auto;
			height: 22px;
		}
	}
}

.offscreen-nav-header {
	margin-bottom: 30px;
	display: flex;
	flex-wrap: nowrap;
	align-items: center;
	justify-content: space-between;
	a {
		display: inline-block;
	}
	img {
		margin: 0;
	}
	.nav-toggle {
		padding-left: 20px;
	}
}