$sans-serif-font: 'open-sans', Verdana, Arial, Helvetica, sans-serif;
$heading-font: 'bebas-neue', Impact, Arial, Helvetica, sans-serif;
$serif-font: Georgia, Times, serif;

$primary-brand:      darken(#4AA7B3, 13%);
$primary-accent:     #2B6168;
$brand-bright:		 #4AA7B3;
$alt-brand:          #3a3a3a;
$alt-accent:         #EFEFEF;
$tan:				 #fcf2e8;
$white:              #FFFFFF;
$yellow:             #fee243;
$light-gray:         #DDDDDD;
$dark-gray:          #555555;

$text-shadow: 0 2px 2px rgba(0, 0, 0, 0.3);
$box-shadow: 0px 2px 5px rgba(0,0,0,0.3);

$border-round: 100px;
$border-radius: 5px;