

/*==========================================================================
Blog
==========================================================================*/ 
.blog-title {
    height: 200px;
    background: url('/images/blogs/bg_blog_title.jpg') $primary-accent no-repeat center top;
    background-size: cover;
    color: $white;
    padding: 10px 20px;
    margin-bottom: 50px;
    h1, h2 {
        background: rgba(43,97,104,0.90);
        font-family: bebas-neue,Impact,Arial,Helvetica,sans-serif;
        font-size: 2.625rem;
        font-weight: 400;
        line-height: 1.2;
        color: $white;
        padding: 10px 20px;
        margin-bottom: 0;
        display: inline-block;
        @media (min-width: 640px) {
            font-size: 3.25rem;
        }
    }
    a {
        color: $white;
        &:hover,&:focus {
            color: $primary-brand;
        }
    }
    > .row {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
    }
}
.blog-details {
    margin-bottom: 30px;
}
.blog-card {
    border: 1px solid $light-gray;
    box-shadow: 2px 4px 5px 0px $light-gray;
    .preview-content {
        margin: 18px 20px;
    }
    h2 {
        font-size: rem-calc(26);
        font-weight: 400;
        color: $primary-accent;
        line-height: 1.2;
    }
    img {
        //max-height: 200px;
        margin: 0;
    }
    .post-date {
        background: $primary-accent;
        font-weight: 600;
        color: $white;
        text-align: center;
        padding: 7px 10px;
    }
    .read-more {
        margin-bottom: 0;
        .button {
            margin-bottom: 0;
        }
    }
    .share {
        margin-top: 20px;
        p {
            font-size: rem-calc(18);
            font-weight: 600;
            color: $primary-accent;
            margin-bottom: 0;
        }
    }
    .addthis_sharing_toolbox {
        height: 32px;
    }
}
.blog-pagination {
    font-size: rem-calc(18);
    text-align: center;
    margin-top: 40px;
    .previous-link {
        margin-right: 20px;
    }
}
.blog-post {
    padding-right: 30px;
    h2, .post-title {
        font-family: $sans-serif-font;
        font-size: rem-calc(30);
        font-weight: 600;
        color: $primary-brand;
        margin-bottom: 1.1rem;
    }
    .h4 {
        font-size: rem-calc(20);
    }
    .post-details {
        font-weight: 600;
        color: $primary-accent;
        margin-bottom: 25px;
    }
    .blog-leadin {
        font-size: rem-calc(18);
        color: $alt-brand;
        border-bottom: solid 3px $primary-brand;
        margin: 0 0 30px;
    }
    .post-footer {
        .share-title, .tag-title {
            font-size: 18px;
            font-weight: bold;
            color: $primary-accent;
        }
        .post-share {
        }
        .post-tags {
            margin: 0 0 10px;
            ul {
                margin: 0;
            }
            li {
                list-style: none;
                margin: 0 10px 0 0;
                display: inline-block;
            }
        }
    }
    .img-caption {
        img {
            margin-bottom: 5px;
        }
        p {
            font-size: rem-calc(14);
            font-style: italic;
        }
    }
}
.blog-aside {
    h3 {
        font-size: rem-calc(24);
        color: $primary-accent;
        margin-bottom: 10px;
    }
    p {
        margin-bottom: 25px;
    }
    .box {
        font-size: rem-calc(14);
        border: 1px solid $light-gray;
        box-shadow: 2px 4px 5px 0px $light-gray;
        padding: 15px 20px 0;
        margin-bottom: 30px;
        .tag-list {
            text-transform: capitalize;
            margin: 0 0 15px;
            li {
                font-size: 15px;
                margin: 0 0 5px;
            }
            .tag-count {
                font-size: 14px;
            }
        }
    }
    .addthis_sharing_toolbox {
        margin-bottom: 20px;
    }
}
.blog-author {
    border-top: solid 1px $light-gray;
    padding-top: 40px;
    margin-top: 35px;
    margin-bottom: 30px;
    .headshot {
        h3 {
            font-size: rem-calc(20);
            margin: 3px 0 0;
        }
        h4 {
            font-size: rem-calc(15);
        }
        img {
            border-radius: $border-round;
            box-shadow: $box-shadow;
        }
        .image {
            text-align: center;
        }
        .icon {
            width: 15px;
            color: $primary-brand;
            text-align: center;
            transition: all .3s;
        }
        ul {
            font-size: rem-calc(14);
            list-style: none;
            margin: 0 0 20px;
            li {
                margin: 0 0 5px;
            }
            .social-contact {
                font-size: 1rem;
                margin: 1px 8px 5px 0;
                display: inline-block;
                .icon {
                    width: auto;
                    font-size: rem-calc(20);
                }
            }
        }
        a:hover .icon,
        a:focus .icon {
            color: $primary-accent;
        }
    }
    .bio {
        h3 {
            font-size: rem-calc(20);
            text-align: center;
            margin: 0 0 3px;
        }
        h4 {
            font-size: rem-calc(16);
            text-align: center;
        }
        p {
            font-size: rem-calc(14);
        }
    }
}
.blog-post .center-img {
    box-shadow: 0px 4px 4px rgba(0,0,0,.5);
    margin: 24px auto;
    display: block;
}
.blog-post hr.thick {
    height: 3px;
}
.blog-cta-text {
    background: $alt-accent;
    border-radius: 5px;
    box-shadow: $box-shadow;
    overflow: hidden;
    h3 {
        background: $primary-accent;
        font-size: rem-calc(20);
        color: $white;
        padding: 12px 25px;
        margin: 0;
        .icon {
            font-size: 1.33333333em;
            margin-right: 5px;
            &.icon-battery-charge {
                width: 25px;
            }
        }
    }
    .details {
        padding: 20px 25px;
    }
}

.blog-inner-nav {
    font-weight: bold;
    .nav-title {
        font-size: rem-calc(20);
        text-align: center;
    }
}

.blog-video {
    margin-bottom: 32px;
}

.blog-list-overview {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 50px;
    .post-date {
        margin-bottom: 0;
    }
    .blog-card {
        display: flex;
        flex-direction: column;
    }
    .preview-content {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
    }
    .read-more {
        margin-bottom: 15px;
    }
    .share {
        margin-top: auto;
    }

}

@media only screen and (min-width: 40em) {
    .blog-list {
        grid-template-columns: 1fr 1fr;
        grid-gap: 30px;
    }
}

@media only screen and (min-width: 60em) {
    .blog-list {
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 30px;
    }
}

@media only screen and (max-width: 640px) {
    .blog-post {
        padding-right: 0.9375rem;
    }
    .blog-author .headshot {
        text-align: center;
    }
    .blog-inner-nav a {
        width: 100%;
        display: block;
        text-align: center;
        margin: 0 0 12px;
    }
}

/*==========================================================================
Recipe Cards
==========================================================================*/
.recipe-card {
    background: url('/images/cssimg/bg_linedpaper.png') $white;
    font-family: 'tekton-pro', sans-serif;
    font-size: rem-calc(14);
    border-radius: 10px;
    padding: 10px 20px 15px;
    margin: 20px 0 35px;
    box-shadow: $box-shadow;
    position: relative;
    overflow: hidden;
    &::before {
        background: url('/images/cssimg/bg_stain.png') right top no-repeat;
        content: '';
        width: 205px;
        height: 195px;
        display: block;
        position: absolute;
        right: 0;
        top: 0;
    }
    .name {
        color: $primary-brand;
        line-height: 1.1;
        margin: 0 0 2px;
        position: relative;
        z-index: 50;
        .label {
            font-size: rem-calc(26);
            font-weight: 700;
        }
        .icon {
            font-size: 20px;
            line-height: .75;
            margin-right: 5px;
        }
    }
    .label {
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        color: $primary-brand;
        text-transform: capitalize;
    }
    .directions {
        font-style: italic;
        margin-bottom: 0;
    }
    .recipe-image img {
        border-radius: $border-radius;
    }
    p {
        font-size: rem-calc(18);
        line-height: 1.3;
        margin: 0 0 23px;
    }
}
@media only screen and (max-width: 640px) {
    .recipe-card {
        .recipe-image img {
            margin-top: 25px;
        }
    }
}
/*==========================================================================
Forms
==========================================================================*/
form input[type="text"],
form input[type="email"],
form select {
    width: 100%;
    height: 48px;
    font-size: rem-calc(16);
    color: $dark-gray;
    border: 1px solid $light-gray;
    padding: 0 0 0 15px;
    box-shadow: none;
}
form textarea {
    width: 100%;
    height: 175px;
    font-size: rem-calc(16);
    color: $dark-gray;
    border: 1px solid $light-gray;
    padding: 15px 15px 0 15px;
    box-shadow: none;
    resize: vertical;
    &[rows] {
    	height: 175px;
    }
}
form input[type="submit"] {
    background: none;
    font-size: 20px;
    color: $primary-brand;
    cursor: pointer;
    padding: 14px 39px;
    text-align: center;
    border: 2px solid $primary-brand;
    margin: 0 0px 25px 0px;
    transition: all 0.3s;
}
form input[type="submit"]:hover {
    background: $primary-brand;
    border-color: $primary-brand;
    color: $white;
}
form .field {
	margin-bottom: 20px;
    select {
        margin-bottom: 0;
    }
    label {
        font-size: 18px;
        color: $white;
        line-height: 1.2;
        margin-bottom: 10px;
        &.small {
            font-size: 1rem;
            font-weight: bold;
        }
    }
    input[type="checkbox"] {
        margin-bottom: 8px;
    }
    input[type="checkbox"] + label {
        font-size: 14px;
    }
}

/*==========================================================================
Callout panels
==========================================================================*/
.header-panel {
    background: $white;
    padding: 0 30px;
    border: 2px solid $primary-brand;
    margin: 60px auto 50px;
    .sign {
        background: $primary-brand;
        border: 2px solid $primary-brand;
        padding: 11px 10px;
        margin: auto;
        display: inline-block;
        position: relative;
        top: -20px;
        left: 50%;
        transform: translateX(-50%);
        h2 {
            font-size: 0.875rem;
            color: $white;
            letter-spacing: 2px;
            text-align: center;
            text-transform: uppercase;
            line-height: 1;
            margin: 0;
        }
    }
    span {
        font-size: 85%;
        font-weight: bold;
        letter-spacing: 1px;
        text-transform: uppercase;
    }
    p {
        margin-bottom: 38px;
    }
}

/*==========================================================================
Simple Quote
==========================================================================*/
.simple-quote {
    max-width: 400px;
    margin-left: 40px;
}

/*==========================================================================
Pricing Callout
==========================================================================*/
.price-callout {
    background: $primary-accent;
    color: $white;
    padding: 15px 15px 15px;
    margin: 32px 0;
    h2 {
        font-family: $heading-font;
        font-size: 24px;
        font-weight: normal;
        color: $white;
        text-transform: uppercase;
        margin: 0;
        span {
            font-size: 44px;
        }
    }
    p {
        margin: 0;
    }
    a {
        color: $yellow;
        &:hover, &:focus {
            color: $primary-brand;
        }
    }
}

@media only screen and (min-width: 48.125em) {
    .price-callout {
        max-width: 550px;
        display: flex;
        align-items: center;
        h2 {
            flex-shrink: 0;
            margin-right: 24px;
        }
    }
}

.price-callout.wide {
    width: 100%;
    max-width: 100%;
    background-color: $primary-brand;
    padding: 25px 15px;
    a:hover {
        color: $primary-accent;
    }
    .button {
        background-color: $primary-accent;
        font-size: 1.125rem;
        color: $white;
        padding: 11px 37px;
        border: solid 1px $white;
        margin: 0 0 5px;
        &:hover, &:focus {
            background-color: $white;
            color: $primary-accent;
        }
    }
    .heading {
        margin-bottom: 12px;
    }
    .cta {
        margin-top: 20px;
        a:not(.button) {
            color: $primary-accent;
            &:hover {
                color: $yellow;
            }
        }
        p {
            font-size: 14px;
            margin-bottom: 0;
        }
    }
}

@media only screen and (min-width: 48.125em) {
    .price-callout.wide {
        padding: 50px 40px;
        .heading {
            margin: 0 20px 0 0;
            flex-shrink: 0;
        }
        .cta {
            text-align: right;
            margin: 0 0 0 40px;
            flex-shrink: 0;
        }
    }
}

/*==========================================================================
Simple Table
==========================================================================*/
.simple-table {
    text-align: center;
    margin-bottom: 32px;
    th {
        background: $primary-brand;
        color: $white;
        padding: 5px 15px;
    }
    td {
        padding: 5px;
    }
    tr:nth-child(odd) td {
        background-color: #EEEEEE;
    }
}

.simple-table-double {
    text-align: center;
    margin-bottom: 32px;
    th {
        background: $primary-brand;
        color: $white;
        padding: 5px 15px;
    }
    td {
        width: 50%;
        text-align: left;
        padding: 5px 10px;
    }
    tr:nth-child(odd) td {
        background-color: #EEEEEE;
    }
}

.simple-table-triple {
    text-align: center;
    margin-bottom: 32px;
    th {
        background: $primary-brand;
        color: $white;
        padding: 5px 15px;
    }
    td {
        width: 33.33%;
        text-align: left;
        padding: 5px 10px;
    }
    tr:nth-child(odd) td {
        background-color: #EEEEEE;
    }
}

td.simple-table-footer {
    font-size: .75em;
    color: $primary-brand;
    text-align: left;
    padding-left: 15px;
    padding-right: 15px;
}


/*==========================================================================
Responsive video
==========================================================================*/
.responsive-video {
    height: 0;
    position: relative;
    padding-bottom: 52.25%; /* 16:9 */
    padding-top: 25px;
    iframe {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }
}