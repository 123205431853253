.simple-callout {
    background: $primary-accent;
    color: $white;
    padding: 15px 20px 25px;
    margin: 32px 0;
    h2 {
        font-family: $heading-font;
        font-size: 44px;
        font-weight: normal;
        color: $white;
        text-transform: uppercase;
        margin: 0 0 12px;
        span {
            font-size: 24px;
            display: block;
        }
    }
    a, .button {
        background-color: $primary-brand;
        color: $white;
        border: 0;
        margin: 0;
        &:hover, &:focus {
            background-color: lighten($primary-brand, 10%);
        }
    }
}

@media only screen and (min-width: 48.125em) {
    .simple-callout {
        max-width: 750px;
        h2 {
            flex-shrink: 0;
            margin-right: 24px;
        }
    }
}