.tippy-box {
	background-color: $white;
	color: $dark-gray;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.tippy-content {
	padding: 10px;
}

.tippy-box[data-placement^="top"] > .tippy-arrow::before {
	border-top-color: $white;
}