@import "functions";
/***************************
Base Styles
***************************/

body {
	background: $white;
	font-family: $sans-serif-font;
	font-size: 16px;
	color: $dark-gray;
}
h1 small, h2 small, h3 small, h4 small, h5 small, h6 small {
	font-size: 60%;
	opacity: .8;
	line-height: 0;
}
h1, .h1 {
	color: $dark-gray;
	line-height: 1;
	margin-bottom: 1.2rem;
}
h2, .h2 {
	font-family: $sans-serif-font;
	font-weight: 600;
	color: $primary-brand;
	margin-bottom: 1.1rem;
}
h3, .h3 {
	font-family: $sans-serif-font;
	font-weight: 600;
	color: $dark-gray;
}
h4, .h4 {
	font-family: $sans-serif-font;
	font-weight: 600;
	color: $primary-accent;
}
h5, .h5 {
	font-family: $sans-serif-font;
	color: $primary-brand;
}
h6, .h6 {
	font-family: $sans-serif-font;
	color: $dark-gray;
}
/* Alternate headings (uses base font) */
h1.alt, .h1.alt {
	font-family: $sans-serif-font;
	font-size: rem-calc(42);
	font-weight: 700;
}
h2.alt, .h2.alt {
	font-family: $heading-font;
	font-size: rem-calc(46);
	font-weight: 400;
}
h3.alt, .h3.alt {
	font-family: $heading-font;
	font-size: rem-calc(40);
	font-weight: 400;
}
h4.alt, .h4.alt {
	font-family: $heading-font;
	font-size: rem-calc(36);
	font-weight: 400;
}
h5.alt, .h5.alt {
	font-family: $heading-font;
	font-size: rem-calc(32);
	font-weight: 400;
}
h6.alt, .h6.alt {
	font-family: $heading-font;
	font-size: rem-calc(30);
	font-weight: 400;
}

.border-heading-wrapper {
	text-align: center;
}

.border-heading {
	font-family: $heading-font;
	font-size: rem-calc(30);
	font-weight: 400;
	color: $primary-accent;
	line-height: 1.1;
	margin-bottom: 10px;
	display: inline-grid;
	grid-template-columns: minmax(20px, 55px) auto minmax(20px, 55px);
	gap: 10px;
	align-items: center;
	&::before,
	&::after {
		content: '';
		border-top: solid 2px $brand-bright;
		border-bottom: solid 2px $brand-bright;
	}
}

p {
	font-size: 1em;
	line-height: 1.5;
	margin: 0 0 20px;
}
ul, ol {
	margin-bottom: 5px;
}
ul {
	margin: 10px 0 5px;
}
ul.arrow li {
	padding-left: 20px;
	list-style: none;
	position: relative;
	margin: 0 0 15px;
}
ul.arrow li::before {
	width: 20px;
	height: 32px;
	content: '';
	background: url('/images/icons/keyboard_arrow_right.svg') no-repeat left top;
	position: absolute;
	left: 0;
	top: -4px;
}

ul.arrow.light li::before {
	background-image: url('/images/icons/keyboard_arrow_right_white.svg');
}

nav ul {
	list-style: none;
	padding: 0;
	margin: 0;
}
nav ul li {
	background: none;
	padding: 0;
	margin: 0;
}
	nav ul li::before {
		display: none;
	}
	nav ul li ul li {
		background: none;
	}
	nav ul li ul li ul {
		display: none !important;
	}
	nav ul li ul li ul li {
		background: none;
	}
ol {
	padding-left: 15px;
	margin-left: 20px;
}
	ol li {
		line-height: 1.4;
		margin: 0 0 14px;
	}
ol li ol {
	margin-top: 8px;
	list-style-type: lower-alpha;
}
	ol li ol li {
		font-size: 1em;
		margin-bottom: 4px;
	}
ol li ol li ol {
	list-style-type: lower-roman;
	margin-bottom: 16px;
}
	ol li ol li ol li {
		font-size: .9em;
	}
a {
	color: $primary-brand;
	transition: all .3s;
}
a:hover, a:focus {
	color: $primary-accent;
}
img {
	margin-bottom: 15px;
}
blockquote,
.panel blockquote {
	font-family: $sans-serif-font;
	border: none;
	padding: 0;
	margin: 0 0 20px;
}
blockquote .quote-wrap {
	background: $primary-brand;
	color: $white;
	line-height: 1.5;
	padding: .875em 1em .25em;
	position: relative;
	margin: 0 0 10px;
}
	blockquote .quote-wrap::after {
		content: '';
		border-style: solid;
		border-width: 11px;
		border-color: $primary-brand $primary-brand transparent transparent;
		position: absolute;
		bottom: -21px;
		left: 38px;
	}
blockquote p,
.panel blockquote p {
	color: $white;
	line-height: 1.5;
	text-shadow: none;
	position: relative;
}
	blockquote cite {
		font-size: 1em;
		font-style: normal;
		color: $dark-gray;
		line-height: 1.2;
		margin: 0 0 0 70px;
	}
	blockquote cite a,
	blockquote cite a:visited {
		color: $primary-accent;
	}
	blockquote cite .speaker {
		font-size: 1.1875em;
		font-weight: 600;
		color: $primary-brand;
	}
	blockquote cite::before {
		content: '';
	}
	blockquote cite img {
		margin-top: 10px;
	}
.panel {
	background: $primary-accent;
	padding: 25px 40px;
	text-align: center;
	margin: 0 0 20px 0;
}
.panel h3 {
	font-size: rem-calc(28);
	color: $primary-brand;
	text-shadow: $text-shadow;
}
.panel p {
	font-weight: 600;
}
.panel .button {
	margin: 0;
}
.panel.multi-col {
	background: $primary-accent;
	padding: 25px 16px;
	margin: 40px 0;
	&.stacked {
		margin: 0;
	}
	&.alt {
		background: $primary-brand;
	}
}
.panel.multi-col h3 {
	margin-bottom: 25px;
}
.panel .button {
	background: none;
	color: $white;
    padding: 18px 45px;
	border-color: $white;
}
.panel .button:hover,
.panel .button:focus {
	background: $white;
	color: $primary-brand;
}
hr {
	background: $primary-brand;
	height: 1px;
	border: none;
	margin: 10px 0 30px;
}
.button {
    background: none;
    color: $primary-brand;
    font-size: rem-calc(20);
	line-height: 1.2;
    text-decoration: none;
    border: solid 2px $primary-brand;
    border-radius: 0;
    padding: 14px 39px;
	transition: all 0.3s ease 0s;
}
.button:hover,
.button:focus {
    background: $primary-brand;
    color: $white;
}
.button.small {
	font-size: rem-calc(15);
	padding: 12px 23px;
}
.button.large {
	font-size: rem-calc(24);
	padding: 18px 30px;
}
.button.x-large {
	font-size: rem-calc(30);
	padding: 20px 30px;
}
.button.gray {
	color: $dark-gray;
	border-color: $light-gray;
	&:hover,
	&:focus {
		background: $light-gray;
	}
}
.button.white {
	color: $white;
	border-color: $white;
	&:hover,
	&:focus {
		background: $white;
		color: $dark-gray;
	}
}
.button.alt {
	background: $primary-brand;
	color: $white;
	&:hover,
	&:focus {
		background: $primary-accent;
		border-color: $primary-accent;
	}
}
.button.alt-small {
	background: $brand-bright;
	font-size: 1rem;
	font-weight: 700;
	color: $white;
	line-height: 1;
	padding: 12px 15px;
	border: 0;
	display: inline-flex;
	align-items: center;
	gap: 12px;
	&:hover,
	&:focus {
		background-color: $primary-brand;
	}
	.icon {
		display: inline-flex;
	}
}

.button.alt-light {
	background: $primary-accent;
	color: $white;
	&:hover,
	&:focus {
		background: $primary-brand;
		border-color: $primary-brand;
	}
}

.text-lrg {font-size:1.375em}
.text-medium {font-size:1.175em}
.text-sml {font-size:.75em}

.text-shadow {text-shadow: $text-shadow;}
.box-shadow {box-shadow: $box-shadow;}

.text-primary-brand {color:$primary-brand;}
.text-primary-accent {color:$primary-accent;}
.text-brand-bright {color:$brand-bright;}
.text-alt-brand {color:$alt-brand;}
.text-alt-accent {color:$alt-accent;}
.text-white {color:$white;}
.text-yellow {color:$yellow;}
.text-light-gray {color:$light-gray;}
.text-dark-gray {color:$dark-gray !important;}

.bg-primary-brand {background-color:$primary-brand;}
.bg-primary-accent {background-color:$primary-accent;}
.bg-alt-brand {background-color:$alt-brand;}
.bg-alt-accent {background-color:$alt-accent;}
.bg-white {background-color:$white;}
.bg-yellow {background-color:$yellow;}
.bg-light-gray {background-color:$light-gray;}
.bg-dark-gray {background-color:$dark-gray;}

.bg-round {border-radius: 10px;}

/* @end */

.mb-0 {margin-bottom: 0 !important;}
.mb-5 {margin-bottom: 5px !important;}
.mb-10 {margin-bottom: 10px !important;}
.mb-15 {margin-bottom: 15px !important;}
.mb-20 {margin-bottom: 20px !important;}
.mb-25 {margin-bottom: 25px !important;}
.mb-30 {margin-bottom: 30px !important;}
.mb-35 {margin-bottom: 35px !important;}
.mb-40 {margin-bottom: 40px !important;}

.mt-0 {margin-top: 0 !important;}
.mt-5 {margin-top: 5px !important;}
.mt-10 {margin-top: 10px !important;}
.mt-15 {margin-top: 15px !important;}
.mt-20 {margin-top: 20px !important;}
.mt-25 {margin-top: 25px !important;}
.mt-30 {margin-top: 30px !important;}
.mt-35 {margin-top: 35px !important;}
.mt-40 {margin-top: 40px !important;}

.stacked {
	margin-top: 0;
	margin-bottom: 0;
}
.content-section-full {
	padding: 30px 0;
}
.content-intro-section {
	padding: 0 0 30px 0;
}
.content-section {
	padding: 25px 0;
	&:last-child {
		padding-bottom: 0;
	}
}
.content-section-top {
	padding: 25px 0 0;
}
.content-sub-section {
	padding-bottom: 20px;
	&:last-child {
		padding-bottom: 0;
	}
}

.img-left,
.img-right {
	display: block;
	margin-left: auto;
	margin-right: auto;
}
.img-left-all {
	float: left;
	margin-right: 20px;
}
.img-right-all {
	float: right;
	margin-left: 20px;
}


@media only screen and (min-width:40.063em) {
	h1 {
		line-height: 1.2;
	}
	.split-list li {
		width: 50%;
		padding-right: 5%;
		float: left;
		&:nth-child(2n+1) {
			clear: left;
		}
	}
	.split-list-3 li {
		width: 30%;
		padding-right: 3%;
		float: left;
		&:nth-child(3n+1) {
			clear: left;
		}
	}
	.content-section-full {
		padding: 75px 0;
	}
	.content-intro-section {
		padding: 0 0 60px;
	}
	.content-section {
		padding: 50px 0;
	}
	.content-section-top {
		padding: 50px 0 0;
	}
	.content-sub-section {
		padding-bottom: 25px;
	}
	.img-left {
		float: left;
		margin-right: 20px;
	}
	.img-right {
		float: right;
		margin-left: 20px;
	}
	.outer-box-space-h {
		margin: 0 35px;
	}
}


.highlight {
	color: $primary-brand;
}
.highlight-alt {
	color: $yellow;
	font-weight: 700;
	text-shadow: $text-shadow;
}
a.highlight-alt,
a > .highlight-alt {
	&:focus,
	&:hover {
		color: $white;
		transition: all .3s;
	}
}
.valign-middle {
    display: table;

    > .column {
        display: table-cell;
        vertical-align: middle;
    }

    > .column, > [class*="column"] + [class*="column"]:last-child {
        float: none;
    }
}
@media only screen and (min-width: 64.063em) {
	.panel.multi-col h3 {
		margin-bottom: 0;
	}
	.panel.multi-col .row > .column {
	    display: table-cell;
	    float: none;
	    vertical-align: middle;
	}
	.panel.multi-col .button {
		white-space: nowrap;
	}
	.img-bottom.row > .column {
		display: table-cell;
		float: none;
		vertical-align: middle;
	}
}

.inner {
	max-width: 62.5rem;
	padding: 0 0.9375rem;
	margin: 0 auto;
	&.wide {
		max-width: 81.25rem;
	}
}

.narrow-container {
	max-width: 800px;
	margin: 0 auto;
}


[x-cloak] { display: none !important; }