/*------------------------------------*\
    @group Background Image Rotator
\*------------------------------------*/
.hero-bg-rotator {
    background-color: $primary-accent;
    background-size: cover;
    position: relative;
    #rotating-item-wrapper {
        height: 500px;
    }
    .hero-content {
        background: rgba(43, 97, 104, 0.9);
        max-width: 50.85rem;
        width: 100%;
        color: $white;
        text-shadow: $text-shadow;
        padding: 20px 15px 30px;
        margin: 0 auto;
    }
    h1 {
        font-size: rem-calc(36);
        color: $white;
        line-height: 1.11;
        margin: 0;
    }
    p {
        font-size: rem-calc(16);;
        color: $white;
        line-height: 1.5;
    }
    .button {
        background: $brand-bright;
        font-weight: 700;
        color: $white;
        text-shadow: none;
        border: 0;
        padding: 12px 20px;
        margin-bottom: 0;
        span {
            font-weight: 700;
            color: $yellow;
        }
        &:hover, &:focus {
            background: $primary-brand;
            color: $white;
        }
    }
    .link-group {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    .rotating-item {
        height: 500px;
        background-size: cover;
        background-position: center top;
        display: none;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        &.img-0 {
            background-image: url('https://bodybasics.imgix.net/slides/melissa_sled_pull_640.jpg?auto=format');
            background-position: left top;
        }
        &.img-1 {background-image: url('https://bodybasics.imgix.net/slides/foam_rolling.jpg?auto=format&w=640');}
        &.img-2 {background-image: url('https://bodybasics.imgix.net/slides/barbell_on_back.jpg?auto=format&w=640');}
    }
}

@media (min-width: 500px) {
    .hero-bg-rotator {
        .hero-content {
            p {
                max-width: 800px;
            }
        }
        .link-group {
            flex-direction: row;
            gap: 20px;
        }
    }
}


@media (min-width: 640px) {
    .hero-bg-rotator {
        .hero-content {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
        #rotating-item-wrapper {
            height: 700px;
        }
        .rotating-item {
            height: 700px;
            background-position: center top;
            &.img-0 {
                background-image: url('https://bodybasics.imgix.net/slides/melissa_sled_pull.jpg?auto=format');
            }
            &.img-1 {
                background-image: url('https://bodybasics.imgix.net/slides/foam_rolling.jpg?auto=format&w=1500');
            }
            &.img-2 {
                background-image: url('https://bodybasics.imgix.net/slides/barbell_on_back.jpg?auto=format&w=1500');
            }
        }
    }
}

@media (min-width: 800px) {
    .hero-bg-rotator {
        h1 {
            font-size: rem-calc(48);
            line-height: 1.2;
        }
        .link-group {
            font-size: rem-calc(20);
            gap: 20px;
        }
    }
}

// @media (min-width: 900px) {
//     .hero-bg-rotator {
//         .hero-content {
//             width: auto;
//         }
//     }
// }

@media  only screen and (-webkit-min-device-pixel-ratio: 1.3) and (max-width: 640px),
        only screen and (-o-min-device-pixel-ratio: 13/10) and (max-width: 640px),
        only screen and (min-resolution: 120dpi) and (max-width: 640px) {
    .hero-bg-rotator {
        .rotating-item {
            // &.img-0 {
            //     background-image: url('https://bodybasics.imgix.net/slides/pat_russian_twist_640@2x.jpg?auto=format');
            // }
            &.img-0 {
                background-image: url('https://bodybasics.imgix.net/slides/melissa_sled_pull_640@2x.jpg?auto=format');
            }
            &.img-1 {background-image: url('https://bodybasics.imgix.net/slides/foam_rolling.jpg?auto=format&w=640&dpr=2');}
            &.img-2 {background-image: url('https://bodybasics.imgix.net/slides/barbell_on_back.jpg?auto=format&w=640&dpr=2');}
        }
    }

}

/* @end Background Image Rotator */


// Short hero
.hero-short {
    height: 200px;
    background-repeat: no-repeat;
    background-position: center bottom;
}

@media (min-width: 93.75em) {
    .hero-short {
        background-size: cover;
    }
}