.blog-preview-cards {
    margin-bottom: 30px;
    display: grid;
    grid-gap: 30px;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
}

.blog-preview-card {
    display: flex;
    border: 1px solid $light-gray;
    box-shadow: 2px 4px 5px 0px $light-gray;
    flex-direction: column;
    .preview-content {
        height: 100%;
        padding: 20px;
        display: flex;
        flex-direction: column;
    }
    h2 {
        font-size: rem-calc(26);
        font-weight: 400;
        color: $primary-accent;
        line-height: 1.2;
    }
    img {
        //max-height: 200px;
        margin: 0;
    }
    .post-date {
        background: $primary-accent;
        font-weight: 600;
        color: $white;
        text-align: center;
        padding: 7px 10px;
        margin: 0;
    }
    .read-more {
        margin-bottom: 0;
        margin-top: auto;
        .button {
            margin-bottom: 0;
        }
    }
}