.team-bio {
	margin-bottom: 60px;
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: 20px;
	grid-template-areas:
	"team-bio-heading"
	"team-bio-headshot"
	"team-bio-text";
	@media only screen and (min-width: 43.75em) {
		margin-bottom: 90px;
		grid-gap: 0 30px;
		grid-template-columns: 200px auto;
		grid-template-rows: auto 1fr;
		grid-template-areas:
			"team-bio-headshot team-bio-heading"
			"team-bio-headshot team-bio-text";
	}
	@media only screen and (min-width: 56.25em) {
		grid-gap: 0 50px;
		grid-template-columns: 250px auto;
	}
	.heading {
		text-align: center;
		grid-area: team-bio-heading;
		@media only screen and (min-width: 43.75em) {
			text-align: left;
		}
		h3 {
			margin-bottom: 10px;
		}
		.subhead {
			font-size: .75em;
			color: $primary-accent;
			margin-top: 4px;
			display: block;
		}
	}
	.text {
		grid-area: team-bio-text;
	}
	.headshot-info {
		max-width: 250px;
		text-align: center;
		margin: 0 auto;
		grid-area: team-bio-headshot;
		img {
			border-radius: 50%;
			border: solid 6px $primary-accent;
		}
		.fact-item {
			h4 {
				font-family: $heading-font;
				font-weight: 400;
				font-size: rem-calc(18);
				line-height: 1.25;
				margin-bottom: 0;
			}
			p {
				margin-bottom: 12px;
			}
		}
	}
	.team-bio-quote {
		background-color: $alt-accent;
		padding: 20px;
		h4 {
			font-family: $heading-font;
			font-size: rem-calc(20);
			font-weight: 400;
			margin-bottom: 5px;
		}
		p {
			font-weight: 700;
			font-style: italic;
			margin: 0;
		}
	}
}