@charset "UTF-8";

/* Slider */

.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
    margin: 0;
}

.slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;
}

.slick-list:focus {
    outline: none;
}


.slick-list.dragging {
    cursor: pointer;
    cursor: hand;
}

.slick-slider .slick-track {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;
}

.slick-track:before,
.slick-track:after {
    content: "";
    display: table;
}

.slick-track:after {
    clear: both;
}

.slick-loading .slick-track {
    visibility: hidden;
}

.slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;
    display: none;
    position: relative;
}

[dir="rtl"] .slick-slide {
    float: right;
}

.slick-slide img {
    display: block;
    margin: 0;
}

.slick-slide.slick-loading img {
    display: none;
}

.slick-slide.dragging img {
    pointer-events: none;
}

.slick-initialized .slick-slide {
    display: block;
}

.slick-loading .slick-slide {
    visibility: hidden;
}

.slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
}


/* Icons */


/* Arrows */

.slick-prev,
.slick-next {
    position: absolute;
    display: block;
    height: 46px;
    width: 46px;
    line-height: 0;
    font-size: 0;
    cursor: pointer;
    background: transparent;
    color: transparent;
    top: 47%;
    margin-top: -10px;
    padding: 0;
    border: none;
    outline: none;
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent;
}

.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
    color: $yellow;
    opacity: 1;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
    opacity: 0.25;
}

.slick-prev:before,
.slick-next:before {
    font-family: "BodyBasics";
    font-size: 46px;
    line-height: 1;
    color: $white;
    text-shadow: $text-shadow;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
    left: 65px;
}

[dir="rtl"] .slick-prev {
    left: auto;
    right: -25px;
}

.slick-prev:before {
    content: "\f000";
}

[dir="rtl"] .slick-prev:before {
    content: "\f001";
}

.slick-next {
    right: 65px;
}

[dir="rtl"] .slick-next {
    left: -25px;
    right: auto;
}

.slick-next:before {
    content: "\f001";
}

[dir="rtl"] .slick-next:before {
    content: "\f000";
}


/* Dots */

.slick-dots {
    position: absolute;
    bottom: -58px;
    list-style: none;
    display: block;
    text-align: center;
    padding: 0;
    width: 100%;
}

.slick-dots li {
    position: relative;
    display: inline-block;
    height: 18px;
    width: 18px;
    border-radius: 50px;
    border: 2px solid #000;
    background: inherit;
    margin: 0 3px;
    padding: 0;
    cursor: pointer;
}

.slick-dots li.slick-active {
    width: 27px;
    height: 27px;
    border: 4px solid #000;
}

.slick-dots li.slick-active button {
    margin-top: 3px;
}

.slick-dots li button {
    border: 0;
    background: transparent;
    display: block;
    height: 20px;
    width: 20px;
    outline: none;
    line-height: 0;
    font-size: 0;
    color: transparent;
    padding: 5px;
    cursor: pointer;
}

.slick-dots li button:hover,
.slick-dots li button:focus {
    outline: none;
}

.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
    color: #0495da;
}

.slick-dots li.slick-active button:before {
    color: #0495da;
    opacity: 0.75;
}
