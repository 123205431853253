.nutrition-overview {
	.nutrition-service-card {
		background-color: $white;
		width: 100%;
		max-width: 400px;
		text-align: center;
		padding: 30px 30px 40px;
		box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
		margin: 0 auto 50px;
		h3 {
			font-family: $heading-font;
			font-size: rem-calc(30);
			font-weight: 400;
			color: $primary-accent;
			margin-bottom: 16px;
		}
		p {
			text-align: left;
			margin-bottom: 30px;
		}
		ul {
			font-size: rem-calc(14);
			margin: 0 0 24px;
		}
		li {
			margin-bottom: 8px;
		}
		.ico {
			width: auto;
			height: 71px;
			margin-bottom: 10px;
			.ico-group {
				width: 96px;
			}
			.ico-person-circle {
				width: 72px;
			}
		}
		.icon {
			font-size: 72px;
			color: $primary-brand;
			margin-bottom: 10px;
		}
		.button {
			text-shadow: 0 1px 1px rgba(0, 0, 0, 0.28);
			margin: 0;
		}
	}
	.nutrition-service-additional {
		margin-top: 40px;
		margin-bottom: 50px;
		h3 {
			font-family: $heading-font;
			font-size: rem-calc(24);
			font-weight: 400;
			color: $primary-accent;
			text-transform: uppercase;
			margin-bottom: 8px;
		}
		li {
			margin-bottom: 8px;
		}
		.heading {
			font-size: rem-calc(32);
			color: $primary-brand;
			text-align: center;
			margin-bottom: 24px;
		}
		.transition-wrap {
			max-width: 800px;
			background-color: #fbfbfb;
			box-shadow: inset 0 -6px 17px rgba(0, 0, 0, 0.08), inset 0 1px 3px rgba(0, 0, 0, 0.2);
			margin: 0 auto;
			position: relative;
			transition: height .3s ease;
			overflow: hidden;
			&::before {
				content: '';
				background-color: $primary-brand;
				width: 100%;
				height: 10px;
				position: absolute;
				left: 0;
				top: 0;
			}
		}
		.nutrition-service-column {
			width: 100%;
			padding: 40px 30px 0;
			&:first-child ul {
				padding-bottom: 0;
			}
			ul {
				padding-bottom: 30px;
				margin-bottom: 0;
			}
		}
	}
	.coach-cards {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
		grid-gap: 28px;
		justify-content: center;
		margin-top: 36px;
		.coach {
			background-image: linear-gradient(#FCF2E8 112px, #FFFFFF 112px, #FFFFFF 100%);
			width: 100%;
			max-width: 315px;
			padding: 112px 30px 30px;
			margin: 36px auto;
			box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
			display: flex;
			flex-direction: column;
			.name {
				font-family: $heading-font;
				font-size: rem-calc(25);
				font-weight: 400;
				color: $primary-brand;
				text-align: center;
				margin-bottom: 4px;
			}
			p {
				margin: 0 0 24px;
			}
			img {
				background-color: $white;
				width: 160px;
				height: 160px;
				box-shadow: 0px 0px 1px rgba(0,0,0,.1);
				margin: 0 auto 20px;
				border-radius: 50%;
				margin-top: -80px;
				display: block;
			}
			.button-wrapper {
				text-align: center;
				margin-top: auto;
			}
			.button {
				display: block;
				margin-bottom: 0;
				&:hover, &:focus {
					background-color: $primary-brand;
				}
			}
		}
	}
	.nutrition-success-stories {
		background-color: $primary-accent;
		color: $white;
		padding-top: 40px;
		padding-bottom: 1px;
		h2 {
			color: $white;
			margin-bottom: 32px;
		}
		.success-story {
			width: 100%;
			max-width: 300px;
			font-size: rem-calc(16);
			margin: 0 auto 39px;
			.quote {
				font-style: italic;
				margin-bottom: 10px;
			}
			.name {
				font-weight: 700;
				color: $yellow;
				margin: 0;
			}
		}
	}
	.nutrition-team-popup {
		background: $white;
		background-image: linear-gradient(#FCF2E8 112px, #FFFFFF 112px, #FFFFFF 100%);
		max-width: 700px;
		width: auto;
		padding: 112px 20px 20px;
		margin: 32px auto;
		position: relative;
		h2 {
			font-family: $heading-font;
			font-size: rem-calc(32);
			font-weight: 400;
			color: $primary-brand;
			text-align: center;
		}
		h3 {
			font-size: rem-calc(20);
		}
		img {
			background: $white;
			width: 160px;
			height: 160px;
			box-shadow: 0px 0px 1px rgba(0,0,0,.1);
			text-align: center;
			border-radius: 50%;
			margin-top: -80px;
			margin-left: auto;
			margin-right: auto;
			display: block;
		}
	}
}

@media screen and (min-width: 640px) {
	.nutrition-overview {
		.nutrition-service-cards {
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-gap: 30px;
			justify-content: center;
			margin-bottom: 60px;
		}
		.nutrition-service-card {
			max-width: 550px;
		}
		.nutrition-service-additional {
			margin-bottom: 60px;
			.transition-wrap {
				display: flex;
				justify-content: center;
			}
			.nutrition-service-column:first-child ul {
				padding-bottom: 30px;
			}
		}
		.coach-cards {
			grid-gap: 32px;
			justify-content: center;
			.coach {
				margin: 0 auto;
			}
		}
		.nutrition-success-stories {
			.success-stories {
				display: grid;
				grid-template-columns: 1fr 1fr;
				grid-column-gap: 24px;
			}
		}
	}
}

@media screen and (min-width: 700px) {
	.nutrition-overview {
		.coach-cards {
			.coach {
				margin-left: auto;
				margin-right: 0;
				&:nth-child(2) {
					margin-left: 0;
					margin-right: auto;
				}
			}
		}
	}
}

@media screen and (min-width: 900px) {
	.nutrition-overview {
		.nutrition-success-stories {
			.success-stories {
				grid-template-columns: 1fr 1fr 1fr;
			}
			.success-story {
				margin-left: 0;
				margin-right: 0;
			}
		}
	}
}


//Nutrition Service
.nutrition-service {
	.hero {
		background-color: $alt-accent;
		height: auto;
		padding: 32px 0 0;
		.inner {
			min-height: 450px;
		}
		.inner > * {
			max-width: 450px;
			margin: 0 auto;
		}
		.hero-text {
			margin-bottom: 32px;
		}
		.hero-image {
			text-align: center;
		}
		h1 {
			font-size: rem-calc(45);
			color: $primary-accent;
			text-shadow: none;
		}
		img {
			margin: 0;
		}
	}
    .nutrition-workshop-details {
    	margin-top: 32px;
    	display: grid;
    	grid-gap: 32px;
    	.heading {
    		font-family: $heading-font;
    		font-size: rem-calc(28);
    		font-weight: 400;
    		color: $primary-brand;
    		line-height: 1.25;
    		text-transform: uppercase;
    	}
    }
	.workshop-description {
    	ul {
    		margin: 0;
    	}
    	li:last-child {
    		margin-bottom: 0;
    	}
	}
	.workshop-highlights {
		.highlight-list {
			display: grid;
			grid-gap: 32px;
			grid-template-columns: repeat(2, 1fr);
			.icon {
				font-size: 40px;
				color: $primary-brand;
				margin-bottom: 6px;
			}
			p {
				font-family: $heading-font;
				font-size: rem-calc(16);
				font-weight: 400;
				color: $primary-accent;
				margin: 0;
			}
		}
	}
	.nutriton-schedule-section {
		h2 {
			text-align: center;
		}
		.schedule-list {
			max-width: 490px;
			margin: 0 auto 64px;
			border-left: solid 2px $light-gray;
		}
		.schedule-item {
			display: flex;
			align-items: center;
			&.past {
				background-color: $white;
				color: #BBBBBB;
				border-color: $alt-accent;
				.start-date::before {
					background-color: $light-gray;
				}
				.duration {
					font-weight: normal;
				}
				.title {
					color: #CCCCCC;
				}
			}
		}
		.start-date {
			width: 100px;
			font-weight: 700;
			text-align: center;
			border-bottom: solid 2px $light-gray;
			position: relative;
			flex-shrink: 0;
			&::before {
				content: '';
				background-color: $primary-brand;
				width: 10px;
				height: 10px;
				border-radius: 50%;
				display: block;
				position: absolute;
				left: -6px;
				bottom: -6px;
			}
		}
		.details {
			background-color: $alt-accent;
			font-size: rem-calc(14);
			padding: 15px 20px 20px;
			margin: 16px 0;
			flex-grow: 1;
			.title {
				font-size: rem-calc(20);
				font-weight: 700;
				color: $primary-brand;
				margin-bottom: 5px;
			}
			.duration {
				font-weight: 700;
				margin-bottom: 5px;
			}
		}
	}

	.testimonial-social-group {
		max-width: 500px;
		margin: 0 auto;
		.nutrition-testimonial {
			background-image: url('/images/icons/ico_quote_outline.svg');
			background-repeat: no-repeat;
			background-position: 0 0;
			background-size: 30px auto;
			color: $primary-brand;
			padding-top: 10px;
			padding-left: 40px;
			margin: 0 auto 48px;
			position: relative;
			h3 {
				color: $white;
			}
			.quote {
				font-style: italic;
			}
			.quotee {
				margin-bottom: 0;
			}
		}
		.social-links {
			padding: 10px;
			border: solid 1px $light-gray;
			display: inline-flex;
			align-items: center;
			margin: 0 0 0 40px;
			h3 {
				font-family: $heading-font;
				font-size: rem-calc(20);
				font-weight: 400;
				color: $primary-brand;
				text-transform: uppercase;
				padding-right: 20px;
				margin: 0;
			}
		}
		.social-link-group {
			display: flex;
			align-items: center;
			gap: 11px;
		}
	}
	.nutrition-testimonial-slider {
		padding-bottom: 40px;
		.slick-dots {
			bottom: 0;
			margin: 0 auto;
			li {
				background-color: $light-gray;
				width: 12px;
				height: 12px;
				opacity: 1;
				border: none;
				&.slick-active {
					background-color: $primary-accent;
					border: none;
					button {
						margin-top: 0;
					}
				}
				button {
					margin-bottom: 0;
				}
			}
		}
	}
	.nutrition-button-large {
		margin-top: 48px;
		text-align: center;
	}
}

@media screen and (min-width: 500px) {
	.nutrition-service {
		.workshop-highlights {
			.highlight-list {
				grid-template-columns: repeat(3, 1fr);
			}
		}
		.testimonial-social-group {
			.nutrition-testimonial {
				background-size: 61px auto;
				padding-left: 80px;
			}
			.social-links {
				margin-left: 80px;
			}
		}
	}
}

@media screen and (min-width: 750px) {
	.nutrition-service {
		.hero {
			padding-top: 48px;
			.inner {
				display: flex;
				align-items: center;
				justify-content: space-between;
				> * {
					max-width: 100%;
					margin: 0;
				}
			}
			.hero-text {
				margin-bottom: 0;
			}
			.hero-image {
				flex-basis: 560px;
				padding-left: 32px;
			}
			p {
				font-size: rem-calc(20);
			}
		}
		.nutrition-workshop-details {
			grid-template-columns: repeat(2, 1fr);
			grid-column-gap: 48px;
		}
		.nutriton-testimonial-section {
			display: flex;
			align-items: center;
			.media {
				flex-basis: 40%;
				margin: 0;
			}
			.testimonial-social-group {
				flex-basis: 60%;
				padding-left: 32px;
			}
		}
	}
}


@media screen and (min-width: 900px) {
	.nutrition-service {
		.nutriton-schedule-section {
			max-width: 100%;
			display: flex;
			justify-content: space-between;
			> div {
				width: 47%;
			}
			h2 {
				text-align: left;
			}
			.schedule-list {
				margin: 0;
			}
		}
		.nutriton-testimonial-section {
			.media {
				flex-basis: 50%;
			}
			.testimonial-social-group {
				flex-basis: 50%;
				padding-left: 48px;
			}
		}
	}
}


// Nutrition testimonial slider
.nutriton-testimonial-section {
	.media {
		text-align: center;
		margin-bottom: 32px;
		img {
			margin: 0;
		}
	}
	.testimonial-social-group {
		max-width: 500px;
		margin: 0 auto;
		.nutrition-testimonial {
			background-image: url('/images/icons/ico_quote_outline.svg');
			background-repeat: no-repeat;
			background-position: 0 0;
			background-size: 30px auto;
			color: $primary-brand;
			padding-top: 10px;
			padding-left: 40px;
			margin: 0 auto 48px;
			position: relative;
			h3 {
				color: $white;
			}
			.quote {
				font-style: italic;
			}
			.quotee {
				margin-bottom: 0;
			}
		}
		.social-links {
			padding: 10px;
			border: solid 1px $light-gray;
			display: inline-flex;
			align-items: center;
			margin: 0 0 0 40px;
			h3 {
				font-family: $heading-font;
				font-size: rem-calc(20);
				font-weight: 400;
				color: $primary-brand;
				text-transform: uppercase;
				padding-right: 20px;
				margin: 0;
			}
			.social-link-group {
				display: flex;
				align-items: center;
				gap: 20px;
			}
		}
	}
}

.nutrition-testimonial-slider {
	padding-bottom: 40px;
	.slick-dots {
		bottom: 0;
		margin: 0 auto;
		li {
			background-color: $light-gray;
			width: 12px;
			height: 12px;
			opacity: 1;
			border: none;
			&.slick-active {
				background-color: $primary-accent;
				border: none;
				button {
					margin-top: 0;
				}
			}
			button {
				margin-bottom: 0;
			}
		}
	}
}


@media screen and (min-width: 500px) {
	.testimonial-social-group {
		.nutrition-testimonial {
			background-size: 61px auto;
			padding-left: 80px;
		}
		.social-links {
			margin-left: 80px;
		}
	}
}

@media screen and (min-width: 750px) {
	.nutriton-testimonial-section {
		display: flex;
		align-items: center;
		.media {
			flex-basis: 40%;
			margin: 0;
		}
		.testimonial-social-group {
			flex-basis: 60%;
			padding-left: 32px;
		}
	}
}

@media screen and (min-width: 900px) {
	.nutriton-testimonial-section {
		.media {
			flex-basis: 50%;
		}
		.testimonial-social-group {
			flex-basis: 50%;
			padding-left: 48px;
		}
	}
}


//Nutrition Patterns
.nutrition-intro-section {
	font-size: rem-calc(18);
	background-color: $primary-accent;
	color: $white;
	margin: 0;
	padding: 50px 0 30px;
	h2 {
		color: $white;
	}
}
.nutrition-section-heading {
	font-family: $heading-font;
	font-size: rem-calc(40);
	color: $primary-accent;
	text-transform: uppercase;
	text-align: center;
}
.nutrition-hero {
    background-color: #FCF2E8;
    color: #285b61;
    padding: 48px 0 160px;
	margin: 0 auto;
	display: flex;
	align-items: center;
	justify-content: center;
	h1 {
		font-size: rem-calc(38);
		color: #285b61;
		margin-bottom: 7px;
	}
	.text {
		max-width: 600px;
		margin: 0 auto 80px;
	}
	.button-group {
		// display: grid;
		// grid-template-columns: repeat(auto-fit, minmax(190px, 1fr));
		// grid-gap: 20px;
		.button {
			// width: 100%;
			color: $primary-brand;
			border-color: $primary-brand;
			padding-left: 14px;
			padding-right: 14px;
			margin: 0;
			// display: block;
			&:hover, &:focus {
				border-color: $white;
			}
		}
	}
	.text-link-group {
		display: flex;
		align-items: center;
		a {
			font-family: $heading-font;
			font-size: rem-calc(30);
			font-weight: 400;
			text-transform: uppercase;
			line-height: 1.1;
		}
		span {
			margin: 0 12px;
		}
	}
	.image {
		text-align: center;
		margin: 0 -.9375rem 40px;
		img {
			width: 100%;
			max-width: 400px;
			margin: 0 auto;
		}
	}
    .button-group {
    	margin-top: 32px;
    }
    .button {
        color: $white;
        border-color: $white;
        margin-right: 16px;
        &:hover, &:focus {
            background-color: $white;
            color: $primary-brand;
        }
    }
    .nutrition-intro-text {
    	text-align: center;
    	margin-bottom: 40px;
    	p {
    		max-width: 675px;
    		margin-left: auto;
    		margin-right: auto;
    	}
    }
}

.nutrition-hero-overlap {
	padding-top: 0;
	padding-bottom: 60px;
	margin-top: -160px;
}

.nutrition-course-cards {
	display: grid;
	grid-column-gap: 30px;
	grid-row-gap: 30px;
	justify-content: center;
	margin-bottom: 32px;
	.course-card {
		max-width: 400px;
		width: 100%;
		padding: 20px 20px 0;
		box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
		display: flex;
		flex-direction: column;
	}
	.course-date {
		font-size: rem-calc(14);
		font-weight: 700;
		color: $primary-accent;
		margin-bottom: 10px;
		p {
			margin: 0;
		}
		span {
			min-width: 48px;
			font-weight: 700;
			display: inline-block;
		}
		+ p {
			margin-bottom: 20px;
		}
	}
	.available-seats {
		position: relative;
		margin-bottom: 22px;
		margin-top: auto;
		> span {
			width: 100%;
			font-size: 14px;
			font-weight: 700;
			color: $white;
			text-align: center;
			display: inline-block;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			z-index: 100;
		}
	}
	.class-size-bar {
		background-color: #ABABAB;
		border-radius: 15px;
		padding: 15px 10px;
		overflow: hidden;
	}
	.class-size-inner-bar {
		background-color: $primary-accent;
	}
	.heading {
		font-family: $heading-font;
		font-size: rem-calc(26);
		font-weight: 400;
		color: $primary-accent;
		text-transform: uppercase;
		margin-bottom: 14px;
		display: flex;
		align-items: center;
		.icon {
			font-size: 40px;
			color: $primary-brand;
			margin-right: 10px;
		}
		.ico {
			width: 53px;
			flex-shrink: 0;
			margin: 0 10px 0 0;
		}
	}
	.class-actions {
		display: flex;
		button {
		    background: none;
		    color: $primary-brand;
		    font-size: rem-calc(16);
			line-height: 1;
		    text-decoration: none;
		    border: solid 2px $primary-brand;
		    border-radius: 0;
		    padding: 14px;
			transition: all 0.3s ease 0s;
			&:hover,
			&:focus {
			    background: $primary-brand;
			    color: $white;
			}
		}
		.learn-more {
			order: 1;
		}
		.signup-link {
			margin-right: 10px;
			order: 0;
		}
		.signup-link button {
			background: $primary-brand;
			color: $white;
			&:hover,
			&:focus {
				background: $primary-accent;
				border-color: $primary-accent;
			}
		}

	}
	p {
		margin-bottom: 10px;
	}
	button {
		padding: 10px 24px;
	}
}

//Course signup modal
.v--modal {
	min-height: 550px;
	display: flex;
	align-items: center;
	justify-content: center;
	.modal-content {
		width: 100%;
		padding: 20px;
		position: relative;
	}
	input[type="submit"] {
		margin-top: 16px;
		margin-bottom: 0;
		&:disabled {
			opacity: .3;
			&:hover, &:focus {
				background-color: transparent;
				color: $primary-brand;
				cursor: default;
			}
		}
	}
	.stripe-logo {
		margin-top: 24px;
	}
	.close-modal {
		font-size: 26px;
		line-height: 1;
		cursor: pointer;
		position: absolute;
		top: 5px;
		right: 10px;
		z-index: 400;
	}
	h3 {
		font-size: rem-clac(20);
		color: $primary-accent;
		padding-right: 20px;
	}
	.class-header {
		font-size: rem-calc(14);
		font-weight: 700;
		color: $primary-accent;
		margin-bottom: 10px;
		p {
			margin-bottom: 0;
		}
		span {
			min-width: 48px;
			font-weight: 700;
			display: inline-block;
		}
	}
	p {
		padding-right: 20px;
		margin-bottom: 20px;
	}
	button {
		margin: 0;
	}
	.stripe-card {
		padding: 10px;
		border: solid 1px $light-gray;
	}
	.successsvg {
		text-align: center;
		h2 {
			font-size: rem-calc(24);
			line-height: 1.3;
			margin-bottom: 0;
		}
		svg {
			max-height: 200px;
		}
	}
}

//Loading styles
.nutrition-loading-classes {
  width: 100%;
  text-align: center;
  h2 {
  	font-size: rem-calc(24);
  }
}
.nutrition-loading-indicator {
  display: inline-block;
  margin-bottom: 10px;
  position: relative;
  left: -20px;
}
.nutrition-loader{
    width: 12px;
    height: 12px;
    border-radius: 50%;
    animation: nutrition-loader 1s linear infinite alternate;
    position: relative;
    left: 0;
}

@keyframes nutrition-loader {
   0%{
        background-color: rgba(74, 167, 179, 1);
        box-shadow: 18px 0px 0px 0px rgba(74, 167, 179,0.2),
                    36px 0px 0px 0px rgba(74, 167, 179,0.2);
      }
    25%{
        background-color: rgba(74, 167, 179, 0.4);
        box-shadow: 18px 0px 0px 0px rgba(74, 167, 179,2),
                    36px 0px 0px 0px rgba(74, 167, 179,0.2);
    }
    75%{ background-color: rgba(74, 167, 179, 0.4);
        box-shadow: 18px 0px 0px 0px rgba(74, 167, 179,0.2),
                    36px 0px 0px 0px rgba(74, 167, 179,1);
      }
}

.nutrition-form-section {
    background-color: $primary-accent;
    color: $white;
    padding: 40px 0;
    h2 {
        font-size: 32px;
        color: $white;
    }
    textarea[rows] {
        height: 100px;
    }
    input[type="text"], input[type="email"], select, textarea {
        margin-bottom: 0;
    }
    .button {
        width: 100%;
        display: block;
        padding: 10px 39px;
        margin: 0;
    }
    a {
    	color: $yellow;
    }
    a:hover, a:focus {
        color: lighten($yellow, 10%);
    }
}

.nutrition-button-group {
	text-align: center;
	margin-top: 60px;
	margin-bottom: 40px;
	.button {
		max-width: 350px;
		width: 100%;
		margin-left: 12px;
		margin-right: 12px;
		.icon {
			margin-right: 12px;
		}
		.ico {
			margin-right: 10px;
			vertical-align: -2px;
		}
	}
	svg {
		height: 19px;
		fill: $white;
	}
}

@media screen and (min-width: 550px) {
}

@media screen and (min-width: 640px) {
	.nutrition-button-group {
		margin-bottom: 10px;
		position: relative;
		&::after {
			content: '';
			background-color: $light-gray;
			width: 90%;
			height: 4px;
			top: 50%;
			left: 50%;
			transform: translate(-50%);
			position: absolute;
			z-index: -1;
		}
		.button {
			max-width: 100%;
			width: auto;
			margin-bottom: 0;
		}
	}
	.nutrition-intro-section {
		p {
			font-size: rem-calc(20);
		}
	}
	.nutrition-hero {
		h1 {
			font-size: rem-calc(45);
			line-height: 1;
		}
	    p {
	    	font-size: rem-calc(18);
	    }
	}
}

@media screen and (min-width: 750px) {
	.nutrition-course-cards {
		grid-template-columns: repeat(2, 1fr);
	}
}

@media screen and (min-width: 800px) {
	.nutrition-hero {
	    padding-top: 60px;
	    overflow: hidden;
	    .hero-content {
	    	min-height: 540px;
	    	padding-bottom: 60px;
	    	margin-bottom: 80px;
	    	display: flex;
			justify-content: space-between;
			align-items: center;
			position: relative;
	    }
		.text {
			max-width: 100%;
			width: 50%;
			margin: 0;
		}
		.image {
			width: 700px;
			text-align: left;
			margin: 0;
			position: absolute;
			left: 52%;
			top: 50%;
			transform: translateY(-50%);
			img {
				max-width: 100%;
				margin: 0;
			}
		}
	}
}

@media screen and (min-width: 1000px) {
	.nutrition-course-cards {
		grid-template-columns: repeat(3, 1fr);
	}
	.nutrition-hero {
		h1 {
			font-size: rem-calc(60);
		}
		.text {
			width: 45%;
		}
		.image {
			left: 50%;
		}
	}
}


//Coaching Application Form
.coaching-application-form {
	max-width: 400px;
	margin: 0 auto;
	.field label {
		font-size: rem-calc(16);
		color: $dark-gray;
	}
	select {
		max-width: 250px;
	}
}


@media screen and (min-width: 550px) {
	.coaching-application-form {
		max-width: 800px;
		.field-group {
			display: flex;
			justify-content: space-between;
			.field {
				width: 48%;
			}
		}
	}
}

// Nutrition image cards
.nutrition-image-cards {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 30px;
	.nutrition-image-card {
		text-align: center;
		padding-top: 25px;
		position: relative;
		.image {
			margin: 0 auto;
			display: inline-block;
			position: relative;
			&::before {
				content: '1';
				background-color: $primary-brand;
				height: 50px;
				width: 50px;
				font-size: 30px;
				color: $white;
				border-radius: 50%;
				border: solid 2px $white;
				box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
				display: inline-flex;
				align-items: center;
				justify-content: center;
				position: absolute;
				top: -25px;
				left: 20px;
			}
		}
		&:nth-child(2) .image::before {content: '2'}
		&:nth-child(3) .image::before {content: '3'}
		&:nth-child(4) .image::before {content: '4'}
		h3 {
			font-size: rem-calc(20);
			color: $primary-accent;
		}
	}
}

@media screen and (min-width: 1000px) {
	.nutrition-image-cards {
		grid-template-columns: repeat(4, 1fr);
		grid-gap: 30px;
		.nutrition-image-card {
			text-align: left;
			h3 {
				font-size: rem-calc(24);
			}
		}
	}
}


// Nutrition quiz
.healthy-lifestyle-quiz {
	max-width: 600px;
	margin: 0;
    .quiz-tooltip-button {
        background: none;
        color: $brand-bright;
        padding: 0;
        margin: 0 0 0 5px;
        display: inline-block;
		svg {
			height: 15px;
			width: 15px;
			position: relative;
			top: 2px;
		}
    }
	label {
		margin-bottom: 4px;
		display: flex;
		align-items: center;
	}
	label > span {
		font-size: rem-calc(18);
		font-weight: bold;
		color: $primary-accent;
	}
	.quiz-field {
		margin-bottom: 20px;
	}
	.quiz-results {
		background-color: #F7F3F3;
		padding: 30px 40px;
		.button {
			margin-bottom: 0;
		}
		h2 {
			font-weight: bold;
			margin-bottom: 8px;
		}
		.result-text {
			font-size: rem-calc(20);
			font-weight: bold;
		}
	}
	.result-callout {
		border-top: solid 1px $brand-bright;
		padding-top: 20px;
	}
}