.option-content-box {
	max-width: 500px;
	margin: 0 auto;
	display: grid;
	gap: 15px;
	h3 {
		color: $white;
		margin-bottom: 10px;
	}
	h4 {
		font-size: rem-calc(20);
		color: $white;
		line-height: 1.2;
		border-bottom: solid 2px $brand-bright;
		padding-bottom: 6px;
		margin-bottom: 6px;
	}
	p {
		margin-bottom: 30px;
	}
	a:not(.button) {
		color: $white;
		text-decoration: underline;
		&:hover, &:focus {
			color: $yellow;
		}
	}
	input::placeholder,
	textarea::placeholder {
		color: black;
	}
	img {
		box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.10);
	}
	.option-details {
		background: $primary-accent;
		color: $white;
		padding: 30px;
		display: grid;
		gap: 30px;
	}
	.option-number {
		width: 50px;
		text-align: center;
		.heading {
			font-family: $heading-font;
			font-size: rem-calc(20);
			font-weight: 400;
			color: $primary-accent;
			line-height: 1;
			margin-bottom: 2px;
		}
		.number {
			width: 50px;
			height: 50px;
			font-family: $heading-font;
			font-size: rem-calc(30);
			font-weight: 400;
			color: $primary-accent;
			border: solid 5px $brand-bright;
			border-radius: 50%;
			line-height: 1;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
}

@media (min-width: 800px) {
	.option-content-box {
		max-width: 100%;
		.option-details {
			grid-template-columns: 5fr 3fr;
		}
	}
	.option-content-box.flip {
		.option-details {
			grid-template-columns: 3fr 5fr;
		}
	}
}

@media (min-width: 900px) {
	.option-content-box {
		grid-template-columns: 50px auto;
	}
}