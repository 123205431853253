.no-padding {
    padding: 0px;
}

/*==========================================================================
HEADER
==========================================================================*/
header {
    width: 100%;
    position: absolute;
    z-index: 99;
}

.utility_navigation {
    background: rgba($primary-accent,.98);
    width: 100%;
    float: left;
    height: auto;
    padding: 5px 0 10px;
    a:hover, a:focus {
        color: $primary-brand;
    }
}

.utility_menu {
    width: auto;
    float: right;
    margin-right: 0;
    .phone {
        img {
            margin: 0 5px 0 0;
        }
    }
    .email {
        a {
            // display: inline-flex;
        }
        img {
            margin: -2px 5px 0 0;
        }
    }
}
.utility_menu .alt-info {
    display: none;
}
.utility_menu ul {
    width: auto;
    float: right;
    margin: 5px 0 0;
}

.utility_menu ul li:first-child {
    border-left: none;
}

.utility_menu ul li {
    list-style: none;
    float: left;
    padding: 0px 12px 0px 14px;
    line-height: 15px;
    border-left: 2px solid $white;
    &:before {
        display: none;
    }
}

.utility_menu ul li a {
    font-size: rem-calc(12);
    color: $white;
    float: left;
    padding: 0px;
    line-height: 18px;
    text-transform: capitalize;
    font-weight: 300;
}

.utility_menu ul li a i {
    font-size: 15px;
    vertical-align: middle;
    margin: 0 7px 0 0;
}

.utility_menu ul a:hover,
.utility_menu ul li.selected a {
    color: #7ae4f2;
}
.utility_menu .social-media .icon {
    font-size: 20px;
    margin-left: 15px;
    &:first-child {
        margin-left: 0;
    }
}
.search-icon span {
    font-size: 17px;
    color: #fff;
    margin: 0 10px;
}

/*==========================================================================
BANNER
==========================================================================*/

.slider {
    max-height: 825px;
    overflow: hidden;
}
.slider > ul {
    margin: 0;
}
.shape {
    margin-bottom: 27px;
}
.slide {
    height: 825px;
    display: none;
    &.slide-first {
        display: block;
    }
    &.slide-pt {
        background: url('https://bodybasics.imgix.net/slides/personal_training_bench.jpg?auto=format') no-repeat;
        background-size: cover;
    }
    &.slide-classes {
        background: url('https://bodybasics.imgix.net/slides/group_class_lunge.jpg?auto=format') no-repeat;
        background-size: cover;
    }
    &.slide-promotion {
        background: url('https://bodybasics.imgix.net/slides/bg_promotion.jpg?auto=format') no-repeat;
        background-size: cover;
    }
    &.slide-promotion-kid-fitness {
        background: url('https://bodybasics.imgix.net/slides/bg_promotion_kids_class.jpg?auto=format') no-repeat;
        background-size: cover;
    }
    &.slide-dumbbells-on-floor {
        background: url('https://bodybasics.imgix.net/slides/bg_dumbbells_on_floor.jpg?auto=format') no-repeat;
        background-size: cover;
    }
    &.slide-james-leading-class {
        background: url('https://bodybasics.imgix.net/slides/bg_james_leading_class.jpg?auto=format') no-repeat;
        background-size: cover;
    }
}
.slider-description .shape img {
    width: auto;
}

.slider-description {
    background: rgba(74,167,179,.5);
    text-shadow: $text-shadow;
    padding: 30px 10px 10px;
    position: absolute;
    top: 32%;
    left: 16.6666%;
    text-align: center;
    .button {
        margin-top: 15px;
    }
}

.slider-description h5.cptn-heading1 {
    color: $white;
}

.slider-description h1.cptn-heading2 {
    font-weight: 400;
    color: #ffffff;
    margin: 0px;
    font-size: 100px;
    &.small-heading {
        font-size: 80px;
    }
}
.slider-description h4.cptn-heading3 {
    font-size: rem-calc(22);
    color: $white;
}
@media (max-width: 1365px) {
    .slick-prev,
    .slick-next {
        top: 46%;
    }
}
@media (max-width: 1199px) {
    .slick-prev {
        left: 20px;
    }
    .slick-next {
        right: 20px;
    }
    .slick-prev,
    .slick-next {
        top: 53%;
    }
}
@media screen and (max-width: 991px) {
    .slider-description h1.cptn-heading2 {
        line-height: 60px;
        font-size: 55px;
        &.small-heading {
            font-size: 50px;
        }
    }
    .slider-description span {
        font-size: 25px;
    }
    .slick-prev:before,
    .slick-next:before {
        font-size: 38px;
    }
}
@media (max-width: 767px) {
    .slide {
        background: rgba(0,0,0,.5);
        position: relative;
        &::before {
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
        }
    }
    .slider-description {
        left: 16%;
        top: 39%;
    }
    .slick-prev {
        left: 0px;
    }
    .slick-next {
        right: 0px;
    }
    .slider-description h1.cptn-heading2 {
        font-size: rem-calc(45);
        line-height: 1;
        &.small-heading {
            font-size: 35px;
        }
    }
    .slider-description h4.cptn-heading3 {
        font-size: rem-calc(20);
    }
}

@media (max-width: 640px) {
    .slide {
        height: 350px;
        &.slide-pt {
            background: url('https://bodybasics.imgix.net/slides/personal_training_bench_640.jpg?auto=format') no-repeat;
            background-size: cover;
        }
        &.slide-classes {
            background: url('https://bodybasics.imgix.net/slides/group_class_lunge_640.jpg?auto=format') no-repeat;
            background-size: cover;
        }
        &.slide-promotion {
            background: url('https://bodybasics.imgix.net/slides/bg_promotion_640.jpg?auto=format') no-repeat;
            background-size: cover;
        }
        &.slide-promotion-kid-fitness {
            background: url('https://bodybasics.imgix.net/slides/bg_promotion_kids_class_640.jpg?auto=format') no-repeat;
            background-size: cover;
        }
        &.slide-dumbbells-on-floor {
            background: url('https://bodybasics.imgix.net/slides/bg_dumbbells_on_floor_640.jpg?auto=format') no-repeat;
            background-size: cover;
        }
        &.slide-james-leading-class {
            background: url('https://bodybasics.imgix.net/slides/bg_james_leading_class_640.jpg?auto=format') no-repeat;
            background-size: cover;
        }
    }
    .slider-description {
        left: auto;
        top: 100px;
        .button {
            font-size: 1rem;
        }
    }
    .slick-prev,
    .slick-next {
        top: auto;
        bottom: 0;
        margin-bottom: 0;
    }
}
@media  only screen and (-webkit-min-device-pixel-ratio: 1.3) and (max-width: 640px),
        only screen and (-o-min-device-pixel-ratio: 13/10) and (max-width: 640px),
        only screen and (min-resolution: 120dpi) and (max-width: 640px) {
    .slide {
        &.slide-pt {
            background: url('https://bodybasics.imgix.net/slides/personal_training_bench_640@2x.jpg?auto=format') no-repeat;
            background-size: cover;
        }
        &.slide-classes {
            background: url('https://bodybasics.imgix.net/slides/group_class_lunge_640@2x.jpg?auto=format') no-repeat;
            background-size: cover;
        }
        &.slide-promotion {
            background: url('https://bodybasics.imgix.net/slides/bg_promotion_640@2x.jpg?auto=format') no-repeat;
            background-size: cover;
        }
        &.slide-promotion-kid-fitness {
            background: url('https://bodybasics.imgix.net/slides/bg_promotion_kids_class_640@2x.jpg?auto=format') no-repeat;
            background-size: cover;
        }
        &.slide-dumbbells-on-floor {
            background: url('https://bodybasics.imgix.net/slides/bg_dumbbells_on_floor_640@2x.jpg?auto=format') no-repeat;
            background-size: cover;
        }
        &.slide-james-leading-class {
            background: url('https://bodybasics.imgix.net/slides/bg_james_leading_class_640@2x.jpg?auto=format') no-repeat;
            background-size: cover;
        }
    }
}
@media (max-width: 479px) {
    .slider-description h4.cptn-heading3 {
        font-size: rem-calc(16);
    }
    .slick-prev:before,
    .slick-next:before {
        font-size: 27px;
    }
}

/*==========================================================================
MIDDLE
==========================================================================*/
.intro-content {
    padding: 40px 0 50px;
}

.about-section {
    width: 100%;
    background-color: $primary-accent;
    padding: 68px 0px 37px 0px;
    position: relative;
    overflow: hidden
}

.about h2 {
    color: $white;
    img {
        margin: -5px 0;
    }
}

.about span {
    font-size: 20px;
    font-weight: 600;
    color: #5e5e5e;
    margin-bottom: 30px;
    display: block;
}

.about p {
    color: $white;
}
.about-image img,
.about-image source {
    position: absolute;
    top: -50px;
    right: 0;
}

.testimonial-section {
    width: 100%;
    text-align: center;
    padding: 52px 0px 40px 0px;
    background: #efefef;
    overflow: hidden;
}

.testimonial-section h2 {
    margin: 3px 0px 70px 0px;
}

.testimonial {
    float: left;
    margin: 0px 25px 66px 0px;
}

.testimonial-content:before {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 13px solid #fff;
    position: absolute;
    top: 35px;
    left: -13px;
}

.testimonial figure {
    width: 100%;
    max-width: 74px;
    margin: 0 auto;
}

.testimonial img {
    border-radius: 50%;
}

.testimonial-content {
    text-align: left;
    padding: 12px 15px 14px 20px;
    background: #fff;
}

.testimonial-content p {
    font-size: 13px;
    color: #8b8b8b;
    margin-bottom: 15px;
}

.testimonial-content h6,
.testimonial-content p.name {
    font-size: rem-calc(15);
    font-weight: 600;
    color: #1f1f1f;
    margin: 0px;
}

.testimonial-section .testimonial:nth-child(2n) {
    margin-right: 0px !important;
}

.blog-highlight-section {
    width: 100%;
    padding: 52px 0px 40px 0px;
    overflow: hidden;
    h2.section-heading {
        text-align: center;
        margin: 3px 0px 70px 0px;
    }
    .share {
        display: none;
    }
}
@media (min-width: 641px) and (max-width: 1024px) {
    .blog-highlight-section {
        .blog-preview-cards .blog-details:nth-child(3) {
            overflow: hidden;
            clear: left;
            margin-left: auto;
            margin-right: auto;
            float: none;
        }
    }
}

@media (max-width: 767px) {
    .testimonial figure {
        margin-bottom: 15px;
    }
    .testimonial figure {
        max-width: 87px;
    }
}
@media (max-width: 639px) {
    .testimonial-section h2 {
        margin-bottom: 40px;
    }
    .testimonial {
        margin-bottom: 50px;
        margin-right: 0;
    }
    .testimonial-content {
        padding-left: 30px;
        padding-right: 30px;
    }
    .testimonial-content:before {
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 13px solid #fff;
        border-top: none;
        left: 50%;
        top: -12px;
        margin-left: -13px;
    }
    .blog-highlight-section {
        h2.section-heading {
            margin-bottom: 40px;
        }
        .blog-details {
            margin-bottom: 50px;
        }
        .blog-card {
            max-width: 360px;
            margin-left: auto;
            margin-right: auto;
        }
    }
}


/*==========================================================================
FOOTER
==========================================================================*/

footer {
    width: 100%;
    float: left;
    clear: both;
}

.footer-top-section {
    width: 100%;
    color: $white;
    float: left;
    background-image: url('https://bodybasics.imgix.net/cssimg/sections/bg_dumbbells.jpg?auto=format');
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
    text-align: center;
    padding: 84px 0;
}

.footer-top-section h2 {
    color: #fff;
    line-height: 46px;
    margin: 0px;
}

.footer-top-section p {
    font-size: 16px;
    color: #fff;
    margin-bottom: 44px;
}
.footer-top-section .button {
    margin-bottom: 40px;
}
.footer-top-section .contact-opt {
    margin: 10px 0;
    &.phone .icon {
        font-size: rem-calc(30);
    }
    .icon {
        vertical-align: middle;
        margin-right: 15px;
        margin-bottom: 0;
    }
    a {
        font-size: rem-calc(22);
        color: $white;
        display: inline-block;
        font-weight: 100;
        vertical-align: middle;
        &:hover, &:focus {
            color: $light-gray;
        }
    }
    &.email a {
        font-size: rem-calc(22);
    }
}

.footer-bottom-section {
    background: $primary-brand;
    width: 100%;
    float: left;
    a {
        color: $white;
        text-decoration: underline;
        text-decoration-thickness: 1px;
        text-underline-offset: 4px;
        &:hover, &:focus {
            color: $white;
            text-decoration-color: $yellow;
        }
    }
}

.footer-bottom-section p {
    font-size: 14px;
    color: $white;
    margin: 15px 0px 0px 0px;
}
.footer-bottom-section .scrollToTop {
    background: $primary-accent;
    text-align: center;
    display: block;
    padding: 10px 0;
    img {
        transition: transform .1s;
        margin: 0;
    }
    &:hover,
    &:focus {
        img {
            transform: scale(1.2);
        }
    }
}
.footer-bottom-section .socialmedia {
    float: right;
    margin: 15px 0 0;
    ul {
        margin: 0;
    }
}

.footer-bottom-section .socialmedia ul li {
    float: none;
    display: inline-block;
}

.footer-bottom-section .socialmedia ul li a {
    color: $white;
    padding: 0 5px;
    margin: 0 5px;
    img {
        max-height: 22px;
        transition: transform .1s;
    }
    &:hover img, &:focus img {
        transform: translateY(-3px);
    }
}

.footer-bottom-section .socialmedia ul li a:hover {
    color: $primary-accent;
    transition: all 0.3s;
}
@media (max-width: 1199px) {
    .footer-top-section p {
        font-size: 14px;
    }
    .footer-top-section {
        padding: 40px 0px 40px 0px;
    }
    .phone figure {
        margin: 15px 0px 20px 0px;
    }
}

@media screen and (max-width: 991px) {
    .socialmedia {
        margin: 16px 6px 0px 0px;
    }
    .socialmedia ul li a {
        font-size: 24px;
        padding: 0 15px;
    }
    .phone a {
        font-size: 26px;
    }
}

@media (max-width: 767px) {
    .footer-bottom-section .socialmedia {
        float: left;
    }
    .footer-bottom-section p {
        font-size: 14px;
        margin: 9px 0px 0px 0px;
    }
    .footer-bottom-section .socialmedia {
        margin: 9px 0px 0px 0px;
    }
    .footer-top-section h2 {
        font-size: 31px;
    }
    .footer-top-section h2 {
        font-size: 26px;
        line-height: 35px;
        margin: 0px 0px 15px 0px;
    }
    .footer-top-section p {
        margin-bottom: 20px;
    }
    .phone figure {
        margin: 0px 0px 12px 0px;
    }
    .footer-top-section {
        padding: 30px 0px 30px 0px;
    }
}
@media (max-width: 639px) {
    .footer-bottom-section p {
        font-size: rem-calc(12);
        text-align: left;
        .break {
            display: block;
        }
    }
    .footer-bottom-section .socialmedia {
        width: 100%;
        margin-top: 15px;
        float: none;
    }
}
@media (max-width: 479px) {
    .footer-bottom-section {
        text-align: center;
    }
    .socialmedia ul {
        text-align: center;
    }
    .socialmedia ul li {
        display: inline-block;
        float: none;
        &::before {
            display: none;
        }
    }
    a.scrollToTop {
        width: 100%;
        display: block;
    }
    .socialmedia ul li a {
        font-size: 22px;
    }
}
@media (max-width: 360px) {
    .footer-top-section .contact-opt .email {
        .icon {
            margin-right: 10px;
        }
        a {
            font-size: rem-calc(20);
        }
    }
}
/*==========================================================================
Inner Template
==========================================================================*/
.inner-template header {
    position: relative;
}
.inner-template .content-section {
    padding-top: 50px;
    &.no-top-pad {
        padding-top: 0;
    }
}
/*==========================================================================
Blog Template
==========================================================================*/
.blog-template .content-section {
    padding-top: 0;
}

/*==========================================================================
Service Templates
==========================================================================*/
.hero {
    background-size: cover;
    background-repeat: no-repeat;
    height: 550px;
    padding-top: 133px;
    &.training-client {
        background-image: url('https://bodybasics.imgix.net/hero/training_christine_trap_bar.jpg?auto=format');
    }
    &.girl-working-out-home {
        background-image: url('https://bodybasics.imgix.net/hero/girl_working_out_home.jpg?auto=format');
    }
    &.personal-training {
        background-image: url('https://bodybasics.imgix.net/hero/personal_training.jpg?auto=format&w=1920');
    }
    &.group-class {
        background-image: url('https://bodybasics.imgix.net/hero/group_class.jpg?auto=format');
    }
    &.group-class-tint {
        background-image: url('https://bodybasics.imgix.net/hero/group_class_tint.jpg?auto=format');
    }
    &.bb-building {
        background-image: url('https://bodybasics.imgix.net/hero/building_exterior.jpg?auto=format');
    }
    &.promotion {
        background-image: url('https://bodybasics.imgix.net/promotions/bg_hero.jpg?auto=format');
    }
    &.promotion-weights-rope {
        background-image: url('https://bodybasics.imgix.net/promotions/bg_hero_weights_rope.jpg?auto=format');
    }
    &.promotion-kid-fitness {
        background-image: url('https://bodybasics.imgix.net/promotions/bg_hero_kids_class.jpg?auto=format');
    }
    &.dumbbells-on-floor {
        background-image: url('https://bodybasics.imgix.net/hero/dumbbells_on_floor.jpg?auto=format');
    }
    &.james-leading-class {
        background-image: url('https://bodybasics.imgix.net/hero/james_leading_class.jpg?auto=format');
    }
    &.trainers {
        background-image: url('https://bodybasics.imgix.net/hero/trainers.jpg?auto=format');
        background-position: center top;
    }
    .hero-content {
        background: rgba(43, 97, 104, 0.7);
        margin-top: 100px;
        padding: 20px 25px 5px;
    }
    h1 {
        font-size: 60px;
        font-weight: 400;
        color: $white;
        text-shadow: $text-shadow;
        margin: 0 0 7px;
        line-height: 1;
    }
    h2 {
        font-size: rem-calc(20);
        color: $white;
    }
}
.form-wrap {
    background: $primary-brand;
    padding: 20px 25px 5px;
    margin: 0 0 30px;
    h3 {
        font-size: rem-calc(22);
        color: $white;
        text-shadow: $text-shadow;
    }
    p {
        font-size: rem-calc(14);
        color: $white;
    }
    .subheading {
        font-size: 16px;
        margin-bottom: 10px;
        margin-top: 15px;
        &.light {
            color: $white;
            text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
        }
    }
    .button {
        width: 100%;
        margin-bottom: 0;
        &.inline {
            width: auto;
        }
    }
    .field-row-2 {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
        gap: 0 30px;
        .field {
            margin-bottom: 30px;
        }
        input {
            margin-bottom: 0;
        }
    }
}

@media (min-width: 800px) {
    .form-wrap {
        margin-left: 20px;
        margin-bottom: 0;
        &.full {
            margin-left: 0;
        }
    }
}
.service-testimonial {
    background: $primary-accent;
    overflow: hidden;
    padding: 40px 0 20px;
    .image img {
        position: absolute;
        margin-bottom: 0;
    }
    cite {
        color: $white;
        font-size: rem-calc(14);
        .speaker {
            font-size: rem-calc(19);
            color: $white;
        }
    }
}
.section-highlight {
    padding-bottom: 50px;
    .media img {
        padding-left: 20px;
    }
    &.flip .media img {
        padding-right: 20px;
        padding-left: 0;
    }
    &.training-facilities {
        .media {
            padding-top: 40px;
        }
    }
}
.column > .section-highlight:last-child {
    padding-bottom: 0;
}
.gallery-wrap {
    text-align: center;
    img {
        border: solid 2px $primary-accent;
        margin: 0;
        &:hover,
        &:focus {
            border-color: $primary-brand;
        }
    }
}
.service-template .questions {
    margin-bottom: 40px;
    dt {
        font-size: rem-calc(18);
        line-height: 1.2;
        margin-bottom: 20px;
        &.active {
            margin-bottom: 12px;
            a {
                color: $primary-brand;
            }
        }
        a {
            color: $primary-accent;
            &:hover,
            &:focus {
                color: $primary-brand;
            }
        }
    }
    dd {
        font-size: rem-calc(14);
        margin-bottom: 25px;
    }
}
@media (min-width: 992px) {
    .bio-james .media img {margin-top: 25px;}
    .bio-leah .media img {margin-top: 15px;}
    .bio-christine .bio-details {padding-top: 22px;}
    .bio-joy .bio-details {padding-top: 15px;}
    .class-bootcamp .media img {margin-top: 15px;}
    .class-cycling .class-details {padding-top: 100px;}
    .class-mobility .media img {margin-top: 30px;}
    .class-corporate-training .media img {margin-top: 30px;}
    .training-trainers .details {padding-top: 75px;}
    .training-facilities .details {padding-top: 30px;}
}
@media (max-width: 850px) {
    .hero {
        padding-top: 190px;
    }
}
@media (max-width: 800px) {
    .service-template .intro .form-wrap {
        margin-left: 0;
    }
}
@media (max-width: 750px) {
    .hero h1 {
        font-size: 52px;
    }
    .hero h2 {
        font-size: rem-calc(18);
    }
}

@media  only screen and (-webkit-min-device-pixel-ratio: 1.3),
only screen and (-o-min-device-pixel-ratio: 13/10),
only screen and (min-resolution: 120dpi) {
    .hero {
        &.girl-working-out-home {
            background-image: url('https://bodybasics.imgix.net/hero/girl_working_out_home@2x.jpg?auto=format');
        }
    }
}

@media (max-width: 640px) {
    .hero {
        height: 400px;
        padding-top: 124px;
        .hero-content {
            margin-top: 50px;
        }
        &.training-client {
            background-image: url('https://bodybasics.imgix.net/hero/training_christine_trap_bar_640.jpg?auto=format');
        }
        &.personal-training {
            background-image: url('https://bodybasics.imgix.net/hero/personal_training.jpg?auto=format&w=640&h=710&fit=crop');
        }
        &.girl-working-out-home {
            background-image: url('https://bodybasics.imgix.net/hero/girl_working_out_home_640.jpg?auto=format');
        }
        &.group-class {
            background-image: url('https://bodybasics.imgix.net/hero/group_class_640.jpg?auto=format');
        }
        &.group-class-tint {
            background-image: url('https://bodybasics.imgix.net/hero/group_class_tint_640.jpg?auto=format');
        }
        &.bb-building {
            background-image: url('https://bodybasics.imgix.net/hero/building_exterior_640.jpg?auto=format');
        }
        &.promotion {
            background-image: url('https://bodybasics.imgix.net/promotions/bg_hero_640.jpg?auto=format');
        }
        &.promotion-weights-rope {
            background-image: url('https://bodybasics.imgix.net/promotions/bg_hero_640_weights_rope.jpg?auto=format');
        }
        &.promotion-kid-fitness {
            background-image: url('https://bodybasics.imgix.net/promotions/bg_hero_640_kids_class.jpg?auto=format');
        }
        &.dumbbells-on-floor {
            background-image: url('https://bodybasics.imgix.net/hero/dumbbells_on_floor_640.jpg?auto=format');
        }
        &.james-leading-class {
            background-image: url('https://bodybasics.imgix.net/hero/james_leading_class_640.jpg?auto=format');
        }
        &.trainers {
            background-image: url('https://bodybasics.imgix.net/hero/trainers_640.jpg?auto=format');
        }
        h2 {
            font-size: rem-calc(18);
        }
    }
    .section-highlight {
        text-align: center;
        &.training-facilities {
            .media {
                padding-top: 0;
            }
        }
    }
    .service-template .intro .form-wrap {
        margin-top: 20px;
    }
    .service-testimonial {
        padding: 20px 0 0;
        .image img {
            position: relative;
        }
    }
}
@media  only screen and (-webkit-min-device-pixel-ratio: 1.3) and (max-width: 640px),
        only screen and (-o-min-device-pixel-ratio: 13/10) and (max-width: 640px),
        only screen and (min-resolution: 120dpi) and (max-width: 640px) {
    .hero {
        &.personal-training {
            background-image: url('https://bodybasics.imgix.net/hero/personal_training.jpg?auto=format&w=640&h=710&fit=crop&dpr=2&q=20');
        }
        &.training-client {
            background-image: url('https://bodybasics.imgix.net/hero/training_christine_trap_bar_640@2x.jpg?auto=format');
        }
        &.girl-working-out-home {
            background-image: url('https://bodybasics.imgix.net/hero/girl_working_out_home_640@2x.jpg?auto=format');
        }
        &.group-class {
            background-image: url('https://bodybasics.imgix.net/hero/group_class_640@2x.jpg?auto=format');
        }
        &.group-class-tint {
            background-image: url('https://bodybasics.imgix.net/hero/group_class_tint_640@2x.jpg?auto=format');
        }
        &.bb-building {
            background-image: url('https://bodybasics.imgix.net/hero/building_exterior_640@2x.jpg?auto=format');
        }
        &.promotion {
            background-image: url('https://bodybasics.imgix.net/promotions/bg_hero_640@2x.jpg?auto=format');
        }
        &.promotion-weights-rope {
            background-image: url('https://bodybasics.imgix.net/promotions/bg_hero_640_weights_rope@2x.jpg?auto=format');
        }
        &.promotion-kid-fitness {
            background-image: url('https://bodybasics.imgix.net/promotions/bg_hero_640_kids_class@2x.jpg?auto=format');
        }
    }

}
/*==========================================================================
Group Fitness
==========================================================================*/
@media (max-width: 640px) {
    .class-schedule h2 {
        text-align: center;
    }
}

/*==========================================================================
About
==========================================================================*/
@media (max-width: 640px) {
    .trainer-bio.section-highlight img {
        padding-left: 0;
        padding-right: 0;
        border-radius: 1000px;
        box-shadow: $box-shadow;
    }
    .trainer-bio.section-highlight.flip img {
        padding-left: 0;
        padding-right: 0;
    }
}

.trainer-bio img {
    width: auto;
}

/*==========================================================================
Promotion
==========================================================================*/
.hero.promotion .hero-content {
    margin-top: 140px;
    h1 .icon {
        font-size: 75%;
        margin-right: 10px;
        vertical-align: middle;
    }
}
.hero.promotion .form-wrap {
    margin-top: 140px;
}
@media (max-width: 640px) {
    .hero.promotion .form-wrap {
        margin: 30px 0 50px;
    }
}

/*==========================================================================
Cards
==========================================================================*/
.card {
    border: 1px solid $light-gray;
    box-shadow: 2px 4px 5px 0px $light-gray;
    margin: 0 0 30px;
    .heading {
        background: $primary-brand;
        font-weight: 600;
        color: $white;
        text-align: center;
        padding: 7px 10px;
    }
    .content {
        margin: 30px 20px 20px;
    }
    h3 {
        font-size: rem-calc(26);
        font-weight: 400;
        color: $primary-accent;
        line-height: 1.2;
    }
    h4 {
        font-size: rem-calc(20);
        color: $dark-gray;
        border-bottom: solid 1px $light-gray;
        padding-bottom: 5px;
        margin: 0 0 5px;
    }
    img {
        max-height: 200px;
        margin: 0;
    }
    ul {
        margin: 0;
    }
    li {
        list-style: none;
        padding: 0;
        margin: 0;
    }
}

/*==========================================================================
Promo Header
==========================================================================*/
.promo-header {
    background: $primary-accent;
    color: $white;
    padding: 15px 0 5px;
    text-align: center;
    .button {
        padding: 5px 15px;
        margin: 0 10px 10px;
    }
    span {
        display: inline-block;
        margin-bottom: 10px;
    }
    a:hover,
    a:focus {
        color: $yellow;
    }
}


/*==========================================================================
Promotion Pages
==========================================================================*/
.simple-schedule .day-schedule {
    margin-bottom: 25px;
}

/*==========================================================================
CONTACT
==========================================================================*/
.contact-main h2 {
    margin-bottom: 35px;
}
.contact-main .contact-form {
    padding-right: 50px;
}
.map-canvas {
    position: relative;
    padding-bottom: 75%; // This is the aspect ratio
    height: 0;
    overflow: hidden;
    margin: 30px 0;
    box-shadow: 2px 4px 5px 0 $light-gray;
    border: 1px solid $light-gray;
    &.short {
        padding-bottom: 50%;
    }
    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100% !important;
        height: 100% !important;
    }
}
.contact {
    margin-bottom: 10px;
}
.contact .icon {
    background: $primary-brand;
    width: 40px;
    height: 40px;
    font-size: 20px;
    color: $white;
    line-height: 40px;
    text-align: center;
    float: left;
    border-radius: 50%;
    margin-right: 10px;
}
.contact a.icon:hover,
.contact a.icon:focus {
    background: $primary-accent;
    color: $white;
    transition: all 0.3s;
}
.contact-detalis {
    text-align: left;
    width: 66%;
    float: left;
}
.contact-circle-icon {
    background: $primary-brand;
    width: 40px;
    height: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    float: left;
    border-radius: 50%;
    margin-right: 10px;
    img {
        margin: 0;
    }
}
.contact h4 {
    font-size: 18px;
    margin: 8px 0 0;
}
.contact.multi-line h4 {
    margin: 0;
}
.contact a {
    color: $primary-accent;
    &:hover,
    &:focus {
        color: $primary-brand;
    }
}
.contact-social {
    margin: 25px 0 40px;
    h3 {
        font-size: rem-calc(24);
        margin-bottom: 5px;
    }
    .contact-social-icon {
        width: 50px;
        height: 44px;
        padding: 5px;
        flex-shrink: 0;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        img {
            margin: 0;
        }
    }
    .contact-social-links {
        display: flex;
        align-items: center;
    }
    .facebook {
        background: #3b5998;
        &:hover,
        &:focus {
            background: lighten(#3b5998,10);
        }
    }
    .instagram {
        background: #3f729b;
        &:hover,
        &:focus {
            background: lighten(#3f729b,10);
        }
    }
    .yelp {
        background: #af0606;
        &:hover,
        &:focus {
            background: lighten(#af0606,10);
        }
    }
    .google-maps {
        background: #dc4e41;
        &:hover,
        &:focus {
            background: lighten(#dc4e41,10);
        }
    }
}
@media (max-width: 640px) {
    .contact-main .contact-form {
        padding-right: 15px;
    }
}

@media (max-width: 1199px) {
    .testimonial-section {
        padding: 35px 0px 30px 0px;
    }
    .testimonial-section h3 {
        margin: 3px 0px 30px 0px;
    }
}

@media screen and (max-width: 991px) {
    .about-section {
        padding: 27px 0px 12px 0px;
    }
    .about-image img,
    .about-image source {
        top: 0;
    }
    .about p {
        font-size: 14px;
        margin: 10px 0px;
    }
    .wrap-map {
        height: 315px
    }
}

@media (max-width: 767px) {
    h3 {
        font-size: 21px;
    }
    .about span {
        font-size: 13px;
    }
    .about p {
        font-size: rem-calc(14);
    }
    .about span.author-name {
        font-size: 27px;
    }
    .about-section {
        padding: 20px 15px;
    }
    .contact h4 {
        font-size: 16px;
        margin: 12px 0px 10px 0px;
    }
}
@media (max-width: 640px) {
    .about-section {
        padding-bottom: 0;
    }
    .about-image {
        text-align: center;
        img, source {
            position: relative;
            margin-top: 35px;
            margin-bottom: 0;
        }
    }
    .contact {
        margin-bottom: 20px;
    }
    .contact.multi-line h4 {
        margin-top: 3px;
    }
}

@media (max-width: 479px) {
    .testimonial-section .medium-6 {
        width: 100%;
    }
    .testimonial-section {
        padding: 28px 0px 37px 0px;
    }
}

/*------------------------------------*\
    @group Group Class Alt Template
\*------------------------------------*/
.group-class-template {
    .hero {
        height: auto;
        background-color: #1B3528;
        padding: 124px 0 30px;
        // padding: 50px 0 30px;
        .button {
            color: $white;
            border-color: $white;
            &:hover, &:focus {
                background-color: $white;
                color: $primary-brand;
            }
        }
        h2 {
            font-size: 1.75rem;
            font-weight: normal;
            line-height: 1.25;
            text-shadow: 0 2px 2px rgba(0,0,0,.5);
        }
    }
    .hero-content {
        background: none;
    }
    .full-form-section {
        background-color: $primary-accent;
        color: $white;
        padding: 40px 0;
        h2 {
            font-size: 32px;
            color: $white;
        }
        textarea[rows] {
            height: 100px;
        }
        input[type="text"], input[type="email"], select, textarea {
            margin-bottom: 0;
        }
        .button {
            width: 100%;
            display: block;
            padding: 10px 39px;
            margin: 0;
        }
        a:hover, a:focus {
            color: $yellow;
        }
    }
    .feature-list-section {
        background-color: $alt-accent;
        font-size: 16px;
        padding: 40px 0 20px;
        h2 {
            font-family: $heading-font;
            font-size: 36px;
            font-weight: normal;
            color: $primary-accent;
            margin-bottom: 40px;
            text-transform: uppercase;
        }
        p {
            color: $primary-accent;
            font-weight: 700;
            margin-bottom: 40px;
        }
        .icon-col:nth-child(2n+3) {
            clear: left;
        }
        .icon {
            font-size: 62px;
            color: $primary-brand;
            margin-bottom: 5px;
        }
    }
    .questions {
        background-color: #EFEFEF;
        padding: 40px 0;
        dl {
            margin-bottom: 0;
        }
    }
    .class-corporate-training {
        padding-top: 40px;
    }
    .class-overview {
        .heading {
            background-color: $white;
            padding: 0 30px 0 15px;
            h2 {
                color: $primary-accent;
                margin: 0;
                padding-bottom: 20px;
            }
        }
        .class-box .inner {
            padding: 20px 20px 40px;
            h3 {
                font-size: 26px;
            }
            *:last-child {
                margin-bottom: 0;
            }
        }
        .first-class {
            background-color: $alt-accent;
            h3 {
                color: $primary-accent;
            }
        }
        .last-class {
            background-color: $primary-accent;
            .inner {
                color: $white;
                h3 {
                    color: $white;
                }
            }
        }
    }
}

@media (min-width: 640px) {
    .group-class-template  {
        .hero {
            height: 550px;
            background-position: right top;
            padding-top: 120px;
            > .row {
                position: relative;
                top: 50%;
                transform: translateY(calc(-50% + 34px))
            }
            .hero-content {
                margin-top: 0;
            }
        }
        .feature-list-section {
            h2 {
                font-size: 52px;
            }
            .icon-col:nth-child(2n+3) {
                clear: none;
            }
            .icon-col:nth-child(3n+4) {
                clear: left;
            }
        }
        .class-overview {
            display: flex;
            flex-wrap: nowrap;
            align-items: flex-end;
            .class-box {
                width: 50%;
            }
        }
    }
}

@media (min-width: 880px) {
    .group-class-template  {
        .hero {
            padding-top: 50px;
        }
    }
}


@media (min-width: 1024px) {
    .group-class-template  {
        .hero {
            background-position: center center;
            > .row {
                transform: translateY(calc(-50% + 54px))
            }
        }
        .feature-list-section {
            .icon-col:nth-child(3n+4) {
                clear: none;
            }
            .icon-col:nth-child(4n+5) {
                clear: none;
            }
        }
    }
}

@media (max-width: 479px) {
    .group-class-template {
        .hero {
            h2 {
                font-size: 1.25rem;
            }
        }
    }
}
/* @end Group Class Alt Template */


/*------------------------------------*\
    @group Email Signup App
\*------------------------------------*/
.email-app-form-wrapper {
    .email-list-options {
        margin-top: 15px;
        margin-bottom: 20px;
        label {
            color: $primary-brand;
            margin-bottom: 7px;
            display: flex;
            input[type="checkbox"] {
                margin: 0 5px 0 0;
            }
        }
    }
    .json-success {
        font-size: 20px;
        color: #ff9900;
        font-weight: bold;
    }
}
/* @end Email Signup App */

/*------------------------------------*\
    @group Careers Template
\*------------------------------------*/
.careers-template {
    .g-recaptcha {
        transform: scale(.75);
        transform-origin: 0;
    }
    .form-wrap {
        margin-left: 0;
    }
    textarea {
        padding-right: 15px;
    }
    .job-description {
        h1 {
            font-size: 36px;
            color: $primary-accent;
            border-bottom: solid 2px #EFEFEF;
            padding-bottom: .3rem;
        }
        h2 {
            font-size: 24px;
        }
    }
    .career-description {
        h1 {
            font-family: $sans-serif-font;
            font-size: 24px;
            font-weight: 600;
            color: $primary-accent;
            line-height: 1.3;
        }
    }
    .career-openings {
        h2 {
            font-size: 24px;
        }
    }
    .career-opening-callout {
        background-color: $primary-brand;
        color: $white;
        padding: 15px 15px 1px;
        h2 {
            color: $white;
            text-transform: uppercase;
            padding-bottom: 5px;
            border-bottom: solid 1px rgba(255,255,255,.3);
        }
        a {
            color: $yellow;
            &:hover, &:focus {
                text-decoration: underline;
            }
        }
        li::before {
            color: $white;
        }
    }
}

@media (min-width: 800px) {
    .careers-template {
        .job-description {
            width: 60%;
        }
        .job-application {
            width: 40%;
        }
    }
}

@media (min-width: 940px) {
    .careers-template {
        .g-recaptcha {
            transform: scale(1);
        }
    }
}
/* @end Careers Template */

.row.wide {
    max-width: 1200px;
}