.alert-bar {
	background-color:#071113;
	color: $white;
	font-weight: bold;
	line-height: 1.1;
	text-align: center;
	padding: 10px 5px;
	a {
		color: $yellow;
		&:hover, &:focus {
			color: lighten($primary-brand, 20%);
		}
	}
}