/* open-sans-regular - latin */
@font-face {
  font-family: 'open-sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('/fonts/open-sans-v17-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Regular'), local('OpenSans-Regular'),
       url('/fonts/open-sans-v17-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/fonts/open-sans-v17-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('/fonts/open-sans-v17-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('/fonts/open-sans-v17-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/fonts/open-sans-v17-latin-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
}

/* open-sans-600 - latin */
@font-face {
  font-family: 'open-sans';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('/fonts/open-sans-v17-latin-600.eot'); /* IE9 Compat Modes */
  src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'),
       url('/fonts/open-sans-v17-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/fonts/open-sans-v17-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('/fonts/open-sans-v17-latin-600.woff') format('woff'), /* Modern Browsers */
       url('/fonts/open-sans-v17-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/fonts/open-sans-v17-latin-600.svg#OpenSans') format('svg'); /* Legacy iOS */
}

/* open-sans-700 - latin */
@font-face {
  font-family: 'open-sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('/fonts/open-sans-v17-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Bold'), local('OpenSans-Bold'),
       url('/fonts/open-sans-v17-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/fonts/open-sans-v17-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('/fonts/open-sans-v17-latin-700.woff') format('woff'), /* Modern Browsers */
       url('/fonts/open-sans-v17-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/fonts/open-sans-v17-latin-700.svg#OpenSans') format('svg'); /* Legacy iOS */
}

/* bebas-neue - latin */
@font-face {
    font-family: 'bebas-neue';
    src: url('/fonts/bebasneue-bold.woff2') format('woff2'),
         url('/fonts/bebasneue-bold.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}