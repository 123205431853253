.remote-pt-template {
	.hero {
		height: 550px;
		display: grid;
		place-content: center;
		h1 {
			font-size: rem-calc(36);
		}
		&.girl-working-out-home {
			background-position: center top;
		}
	}
	.flex-video.widescreen {
		padding-top: 0;
	}
}


@media (min-width: 40em) {
	.remote-pt-template {
		.hero {
			height: 720px;
			h1 {
				font-size: 40px;
			}
		}
	}
}

@media (min-width: 64em) {
	.remote-pt-template {
		.hero {
			h1 {
				font-size: 60px;
			}
		}
	}
}

.remote-training-intro {
	background: $tan;
	font-size: rem-calc(18);
	padding: 32px 0 12px;
	.inner {
		max-width: 750px;
		margin: 0 auto;
	}
	@media (min-width: 40em) {
		font-size: rem-calc(20);
		padding: 48px 0 28px;
	}
}

.remote-training-options-intro {
	h2 {
		font-size: rem-calc(24);
		font-weight: 700;
		text-align: center;
		margin-bottom: 48px;
	}
}

.remote-training-options {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
	grid-gap: 40px 80px;
	margin-bottom: 60px;
}

.remote-training-option {
	h3 {
		background-image: linear-gradient(to right, $primary-accent, $primary-brand);
		font-size: 24px;
		font-weight: 700;
		color: $white;
		padding: 10px 19px;
		margin: 0 0 16px;
	}
}

.remote-training-steps {
	h2 {
		text-align: center;
	}
}

.remote-training-step {
	padding-bottom: 60px;
	position: relative;
	&::before {
		content: '';
		height: 100%;
		width: 2px;
		border-left: dashed 2px $primary-brand;
		position: absolute;
		top: 0;
		left: 24px;
	}
	h3 {
		position: relative;
		z-index: 30;
		display: flex;
		align-items: center;
	}
	&.no-connector::before {
		display: none;
	}
}

.remote-training-step-inner {
	padding-left: 58px;
	p {
		max-width: 750px;
	}
}

.remote-training-step-number {
	width: 50px;
	height: 50px;
	background-color: $primary-accent;
	background-image: linear-gradient($primary-accent, $primary-brand);
	color: $white;
	border-radius: 50%;
	padding: 10px;
	margin-right: 8px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
}

.remote-training-form {
	background-color: $primary-accent;
	padding: 20px;
	form {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
		grid-column-gap: 40px;
		.field label {
			font-size: 14px;
			color: $white;
		}
		.field-full {
			grid-column: 1 / -1;
		}
		.field-2 {
			grid-column: 1 / 2;
		}
		.field-3 {
			grid-column: 1 / 3;
		}
		.button {
			margin-bottom: 0;
		}
		.field.last {
			margin-bottom: 0;
		}
		select {
			max-width: 400px;
		}
		input[type="text"],
		input[type="email"] {
			margin-bottom: 0;
		}
	}
}