.pt-template {
	.hero {
		height: 550px;
		padding-top: 0;
		display: grid;
		place-content: center;
		.hero-content {
			margin-top: 0;
		}
		h1 {
			font-size: rem-calc(36);
		}
		&.personal-training {
			background-position: center top;
		}
	}
	header {
		position: relative;
	}
	.flex-video.widescreen {
		padding-top: 0;
	}
}


@media (min-width: 40em) {
	.pt-template {
		.hero {
			height: 720px;
			h1 {
				font-size: 40px;
			}
		}
	}
}

@media (min-width: 64em) {
	.pt-template {
		.hero {
			h1 {
				font-size: 60px;
			}
		}
	}
}

.pt-template .pt-details {
	.inner {
		display: grid;
		grid-gap: 40px;
	}
}

@media (min-width: 45em) {
	.pt-template .pt-details {
		.inner {
			grid-template-columns: 6fr 4fr;
		}
	}
}

@media (min-width: 65em) {
	.pt-template .pt-details {
		.inner {
			grid-gap: 60px;
		}
	}
}