.page-header {
	background: rgba(74, 167, 179, 0.9);
}

.page-header-top {
	background: rgba($primary-accent,.98);
	padding: 10px 0;
	display: none;
	.extra-nav {
		font-size: rem-calc(12);
		display: flex;
		align-items: center;
		justify-content: flex-end;
		gap: 14px;
	}
	a {
		color: $white;
		&:hover, &:focus {
			color: $yellow;
		}
	}
	img {
		margin: 0;
	}
	.divider {
		height: 20px;
		width: 2px;
		background: $white;
	}
	.icon {
		display: inline-flex;
		align-items: center;
		justify-content: flex-start;
		gap: 5px;
		&.phone img {
			margin-bottom: -1px;
		}
	}
	.social-media {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 15px;
		a {
			display: flex;
		}
		svg {
			width: auto;
			max-height: 20px;
		}
	}
}

@media (min-width: 40em) {
	.page-header-top {
		display: block;
	}
}



.page-header-main {
	padding: 10px 0;
	.logo {
		max-width: 200px;
		a {
			display: block;
		}
	}
	img {
		margin: 0;
	}
	.inner {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 10px 40px;

	}
	.large-nav {
		display: none;
	}
}

@media (min-width: 40em) {
	.page-header-main {
		padding: 12px 0;
		.inner {
			flex-wrap: wrap;
		}
		.nav-toggle {
			display: none;
		}
		.large-nav {
			display: block;
			a {
				font-size: rem-calc(14);
				color: $white;
				padding: 2px 0 1px;
				border-bottom: 2px solid transparent;
				&:hover, &:focus {
					border-color: $white;
				}
			}
			.selected a {
				border-color: $white;
			}
		}
		ul {
			display: flex;
			flex-wrap: nowrap;
			gap: 20px;
		}
	}
}

@media (min-width: 62.5em) {
	.page-header-main {
		.logo {
			max-width: 100%;
		}
		ul {
			gap: 30px;
		}
	}
}

@media (min-width: 66em) {
	.page-header-main {
		.large-nav a {
			font-size: rem-calc(18);
		}
	}
}
