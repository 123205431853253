.location-landing-page {
	.hero {
		background: url('https://bodybasics.imgix.net/hero/katie_training_kettlebell.jpg?auto=format&w=640') no-repeat center top #c6c9c2;
		background-size: cover;
		height: 550px;
		display: flex;
		align-items: flex-end;
		h1 {
			font-size: rem-calc(36);
			margin-bottom: 0;
		}
		.hero-content {
			padding: 20px;
			display: inline-block;
		}
		&.girl-working-out-home {
			background-position: center top;
		}
	}
	.flex-video.widescreen {
		padding-top: 0;
	}
	.gallery-wrap {
		max-width: 620px;
	}
	h2 {
		font-size: rem-calc(40);
		font-family: $heading-font;
		font-weight: 400;
		color: $primary-accent;
	}
	h3 {
		font-family: $heading-font;
		font-size: rem-calc(25);
		font-weight: 400;
		color: $primary-accent;
		line-height: 1.1;
		margin-bottom: 5px;
	}
}

@media  only screen and (-webkit-min-device-pixel-ratio: 1.3),
only screen and (-o-min-device-pixel-ratio: 13/10),
only screen and (min-resolution: 120dpi) {
	.location-landing-page {
		.hero {
			background-image: url('https://bodybasics.imgix.net/hero/katie_training_kettlebell.jpg?auto=format&w=640&dpr=2&q=15');
		}
	}
}

@media (min-width: 40em) {
	.location-landing-page {
		.hero {
			background-image: url('https://bodybasics.imgix.net/hero/katie_training_kettlebell.jpg?auto=format&w=1200');
			height: 720px;
			justify-content: flex-end;
			@media  only screen and (-webkit-min-device-pixel-ratio: 1.3),
			only screen and (-o-min-device-pixel-ratio: 13/10),
			only screen and (min-resolution: 120dpi) {
				background-image: url('https://bodybasics.imgix.net/hero/katie_training_kettlebell.jpg?auto=format&w=1200&dpr=2&q=15');
			}
			.row {
				margin-bottom: 50px;
			}
			.hero-content {
				margin-right: .9375rem;
				margin-left: .9375rem;
			}
			h1 {
				font-size: 40px;
			}
		}
	}
}

@media (min-width: 75em) {
	.location-landing-page {
		.hero {
			background-image: url('https://bodybasics.imgix.net/hero/katie_training_kettlebell.jpg?auto=format&w=1800');
			@media  only screen and (-webkit-min-device-pixel-ratio: 1.3),
			only screen and (-o-min-device-pixel-ratio: 13/10),
			only screen and (min-resolution: 120dpi) {
				background-image: url('https://bodybasics.imgix.net/hero/katie_training_kettlebell.jpg?auto=format&w=1800&dpr=2&q=15');
			}
		}
	}
}

@media (min-width: 112.5em) {
	.location-landing-page {
		.hero {
			background-image: url('https://bodybasics.imgix.net/hero/katie_training_kettlebell.jpg?auto=format&w=2200&h=1000&fit=crop');
			@media  only screen and (-webkit-min-device-pixel-ratio: 1.3),
			only screen and (-o-min-device-pixel-ratio: 13/10),
			only screen and (min-resolution: 120dpi) {
				background-image: url('https://bodybasics.imgix.net/hero/katie_training_kettlebell.jpg?auto=format&w=2200&h=1000&fit=crop&dpr=2&q=15');
			}
		}
	}
}

.location-landing-intro {
	font-size: rem-calc(16);
	padding: 0 15px;
	margin-bottom: 60px;
	.inner {
		background: $white;
		box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.2);
		max-width: 850px;
		padding: 30px 30px;
		margin: -155px auto 0;
	}
	.media {
		text-align: center;
	}
	h1 {
		font-size: rem-calc(42);
		color: $primary-accent;
		margin-bottom: 10px;
	}
	b {
		color: $primary-accent;
	}
	form {
		margin-bottom: 16px;
		display: flex;
		align-items: center;
	}
	input[type="email"] {
		height: 56px;
		border-color: $light-gray;
		margin: 0;
	}
	input[type="submit"] {
		background-color: $brand-bright;
		color: $white;
		border-color: $brand-bright;;
		text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.28);
		margin: 0 0 0 20px;
		&:hover, &:focus {
			background-color: $primary-brand;
			border-color: $primary-brand;
		}
	}
	.json-success {
		font-size: rem-calc(24);
	}
	.disclaimer {
		margin-bottom: 0;
	}
}

@media (min-width: 50em) {
	.location-landing-intro {
		.inner {
			display: flex;
			align-items: flex-start;
		}
		.media {
			flex-shrink: 0;
		}
		.text {
			padding-left: 30px;
		}
	}
}

.location-highlights {
	margin-bottom: 40px;
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
	grid-gap: 30px;
	h2 {
		font-size: rem-calc(30);
		margin-bottom: 12px;
		display: flex;
		align-items: center;
		img {
			margin: 0 7px 0 0;
		}
	}
	p {
		margin-bottom: 10px;
	}
	ul.arrow li {
		margin-bottom: 10px;
	}
}

.location-map {
	margin-bottom: 60px;
	h2 {
		text-align: center;
		margin-bottom: 10px;
	}
	.map-canvas {
		margin-top: 0;
	}
}

.location-details {
	margin-bottom: 40px;
	.text-large {
		font-size: rem-calc(24);
		font-weight: 700;
		line-height: 1.25;
	}
	.simple-callout {
		max-width: 320px;
		background-color: $tan;
		color: $primary-accent;
		margin-top: 0;
		h2 {
			color: $primary-accent;
			margin-right: 0;
		}
	}
}

@media (min-width: 40em) {
	.location-details {
		display: flex;
		align-items: flex-start;
		.content {
			padding-right: 30px;
		}
		.simple-callout {
			flex-shrink: 0;
		}
	}
}